// Light theme variables

// Bootstrap base colors
$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #7987a1 !default;
$gray-700: #495057 !default;
$gray-800: #212a3a !default;
$gray-900: #060c17 !default;
$black: #000 !default;
$text-muted: $gray-600 !default;
$light-gray: #d3d3d3;
$dark-gray: #e6e7e8;

// Bootstrap custom colors
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
$lightpurple: #6f2da8 !default;
$light-orange: #fae9d8 !default;

// --- Bootstrap Theme Colors --- //
//
$primary: #027ea8 !default;
$primary-btn: #f6851f !default;
$primary-disabled-btn: #f6851f !default;
$secondary: #103558 !default;
$secondary-light: #282e3d !default;
$success: #05a34a !default;
$info: #66d1d1 !default;
$warning: #fbbc06 !default;
$danger: #eb1d50 !default;
$light: $gray-200 !default;
$dark: $gray-900 !default;
$inProgress: #f8ed92 !default;
$light-primary: rgba(2, 117, 216, 0.5);
$light-blue: #f7f8fa;
$light-red: #e6c3c7;
$sky: #88c9e3;
//
// --- Bootstrap Theme Colors --- //

// Social colors
$social-colors: (
    'facebook': #3b5998,
    'twitter': #1da1f2,
    'google': #dc4e41,
    'youtube': #f00,
    'vimeo': #1ab7ea,
    'dribbble': #ea4c89,
    'github': #181717,
    'instagram': #e4405f,
    'pinterest': #bd081c,
    'flickr': #0063dc,
    'bitbucket': #0052cc,
    'linkedin': #0077b5,
) !default;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 3 !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-gradients: false !default;
$enable-negative-margins: true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.
$spacer: 1rem !default;
$spacers: (
    0: 0,
    1: $spacer * 0.25,
    2: $spacer * 0.5,
    3: $spacer,
    4: $spacer * 1.5,
    5: $spacer * 3,
    6: (
        $spacer * 4.5,
    ),
    7: (
        $spacer * 6,
    ),
);

// Position
//
// Define the edge positioning anchors of the position utilities.
$position-values: (
    0: 0,
    10: 10%,
    20: 20%,
    25: 25%,
    30: 30%,
    40: 40%,
    50: 50%,
    60: 60%,
    70: 70%,
    75: 75%,
    80: 80%,
    90: 90%,
    100: 100%,
) !default;

// Body
//
// Settings for the `<body>` element.
$body-bg: #f9fafb !default;
$body-color: $black !default;

// Links
//
// Style anchor elements.
$link-decoration: none !default;
// $link-hover-decoration:        underline !default;

// Paragraphs
//
// Style p element.
$paragraph-margin-bottom: 0 !default;

// Grid columns
$grid-gutter-width: 1.5rem !default;

// Components
//
// Define common padding and border radius sizes and more.

// Border
$border-color: $gray-200 !default;

// Border Radiues
$border-radius: 0.25rem !default;

$action-transition-duration: 0.2s;
$action-transition-timing-function: ease;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// Font family
$font-family-sans-serif: 'Roboto', Helvetica, sans-serif !default;

$font-size-base: 0.875rem !default; // 14px
$font-size-lg: 1rem !default; // 16px
$font-size-sm: 0.812rem !default; // 13px

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 500 !default;
$font-weight-bolder: 700 !default;
$font-weight-boldest: 900 !default;

$font-weight-base: $font-weight-normal !default;

// Heading sizes
$h1-font-size: 2.5rem !default;
$h2-font-size: 2rem !default;
$h3-font-size: 1.5rem !default;
$h4-font-size: 1.25rem !default;
$h5-font-size: 1rem !default;
$h6-font-size: $font-size-base !default;

$headings-margin-bottom: 0 !default;
$headings-font-weight: 500 !default;

$hr-opacity: 0.1 !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding-y: 0.85rem !default;
$table-cell-padding-x: 0.85rem !default;
$table-cell-padding-y-sm: 0.55rem !default;
$table-cell-padding-x-sm: 0.55rem !default;

$table-striped-bg: $gray-200 !default;
$table-active-bg: $gray-300 !default;
$table-hover-bg: $gray-200 !default;

$table-group-separator-color: $border-color;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.469rem !default;
$input-btn-padding-x: 0.8rem !default;

$input-btn-focus-width: 0;
$input-btn-focus-box-shadow: none !default;

$input-placeholder-color: $gray-500 !default;

$input-btn-padding-y-xs: 0.313rem !default;
$input-btn-padding-x-xs: 0.8rem !default;
$input-btn-font-size-xs: 0.75rem !default;

$input-btn-padding-y-sm: 0.391rem !default;
$input-btn-padding-x-sm: 0.8rem !default;
$input-btn-font-size-sm: $font-size-sm !default;

$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-padding-x-lg: 0.8rem !default;
$input-btn-font-size-lg: $font-size-lg !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-font-weight: $font-weight-normal !default;

$btn-padding-y-xs: $input-btn-padding-y-xs !default;
$btn-padding-x-xs: $input-btn-padding-x-xs !default;
$btn-font-size-xs: $input-btn-font-size-xs !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-sm: $border-radius !default;
$btn-border-radius-lg: $border-radius !default;

// Forms

$input-padding-y-xs: $input-btn-padding-y-xs !default;
$input-padding-x-xs: $input-btn-padding-x-xs !default;
$input-font-size-xs: $input-btn-font-size-xs !default;

$input-bg: $white !default;
$input-border-color: $border-color !default;
$input-focus-border-color: $gray-400 !default;

$input-border-radius: $btn-border-radius !default;
$input-border-radius-sm: $btn-border-radius-sm !default;
$input-border-radius-lg: $btn-border-radius-lg !default;

// form-check
$form-check-input-width: 1.3em !default;
$form-check-input-border-radius: 0.15em !default;

// Form select
$form-select-indicator-color: $gray-600 !default;

// Input-group
$input-group-addon-padding-x: 0.563rem !default;
$input-group-addon-bg: $gray-100 !default;

// Navs
$nav-tabs-link-bg: $gray-100; // custom variable
$nav-tabs-link-border-color: $border-color $border-color $gray-300; // custom variable
$nav-tabs-link-active-bg: $white;

// Dropdowns
$dropdown-border-color: #f2f4f9;
$dropdown-box-shadow: 0 5px 10px 0 rgba(183, 192, 206, 0.2);

// Pagination
$pagination-padding-y: 0.469rem !default;
$pagination-padding-x: 1rem !default;
$pagination-padding-y-sm: 0.391rem !default;
$pagination-padding-x-sm: 0.75rem !default;
$pagination-padding-y-lg: 0.5rem !default;
$pagination-padding-x-lg: 1.1rem !default;
$pagination-color: $primary;
$pagination-active-bg: $primary;
$pagination-active-border-color: $primary;

// Cards
$card-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2); // custom variable
$card-spacer-y: 1.5rem !default;
$card-spacer-x: 1.5rem !default;
$card-title-spacer-y: 0.875rem !default;
$card-border-color: #f2f4f9 !default;
$card-cap-padding-y: 0.875rem !default;
$card-cap-padding-x: $card-spacer-x !default;
$card-cap-bg: rgba($black, 0.01) !default;

// Accordion
$accordion-bg: $white !default;
$accordion-border-color: $border-color !default;
$accordion-icon-width: 0.875rem !default;

// Popovers
$popover-border-color: $border-color !default;
$popover-header-bg: $gray-200 !default;

// Badges
$badge-font-size: 0.8em !default;
$badge-font-weight: $font-weight-bold !default;

// Modals
$modal-content-border-color: $border-color !default;
$modal-fade-transform: scale(0.8) !default;
$modal-transition: transform 0.4s ease !default;
// Modals

// List group
$list-group-item-padding-y: 0.75rem !default;
$list-group-item-padding-x: 1.25rem !default;

// Close
$btn-close-width: 0.8em !default;
$btn-close-color: $black !default;

// Navbar
$navbar-height: 70px;
$navbar-dark-bg: #103558 !important;
$navbar-light-bg: #103558 !important;

$btn-green: #00a711;
$btn-blue: #000aff;
$btn-yellow: #aab900;
$btn-saffron: #ff7a00;
$btn-purple: #db00ff;
$btn-red: #ff0000;
$btn-dark-gray: #565656;
$btn-gray: #888383;
