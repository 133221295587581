/* You can add global styles to this file, and also import other style files */

// Global icon font
@import './assets/fonts/feather/style.css';

// // 3rd party plugins
@import '@mdi/font/css/materialdesignicons.min.css';
@import 'highlight.js/styles/googlecode.css';
//@import 'ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.carousel.min.css';
//@import 'ngx-owl-carousel-o/lib/styles/prebuilt-themes/owl.theme.default.min.css';
@import 'animate.css/animate.min.css';
@import '@ng-select/ng-select/themes/default.theme.css';
@import 'metismenujs/scss/metismenujs';
@import 'dropzone/dist/min/dropzone.min.css';
@import 'quill/dist/quill.snow.css';
// @import 'angular-archwizard/styles/archwizard.css';
@import 'simple-datatables/dist/style.css';
//@import '@swimlane/ngx-datatable/index.css';
//@import '@swimlane/ngx-datatable/themes/bootstrap.css';
//@import '@swimlane/ngx-datatable/assets/icons.css';

@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

// NobleUI styles for LTR
@import './assets/scss/style';
@import '@mobiscroll/angular/dist/css/mobiscroll.scss';
@import './../node_modules/ng-zorro-antd/ng-zorro-antd.min.css';

// NobleUI styles For RTL
// @import "./assets/scss/style.rtl.css";

.custom-select {
    background: #fff;
}
form label {
    margin-bottom: 0.2rem;
    font-weight: 500;
    letter-spacing: 0.02rem;
}

table.dataTable > thead > tr > th,
table.dataTable > tbody > tr > td {
    border-bottom: 1px solid #e9ecef !important;
    vertical-align: middle;
    padding: 0.8rem 0.9rem;
    font-size: 14px !important;
}
.table-hover > tbody > tr:hover > * {
    --bs-table-accent-bg: rgba(0, 0, 0, 0.02);
}
.dataTables_paginate .btn.btn-icon {
    margin: 0.1rem;
    font-weight: 500;
    background: #fff;
    color: #0c1427;
}
.dataTables_paginate .btn.btn-icon:hover,
.dataTables_paginate .btn.btn-icon.current {
    background: #0c1427;
    border-color: #0c1427;
    color: #fff;
}

.dataTables_paginate .first .fa::after {
    content: '\e933';
    font-family: 'feather' !important;
    font-style: normal;
}
.dataTables_paginate .previous .fa::after {
    content: '\e92f';
    font-family: 'feather' !important;
    font-style: normal;
}
.dataTables_paginate .next .fa::after {
    content: '\e930';
    font-family: 'feather' !important;
    font-style: normal;
}
.dataTables_paginate .last .fa::after {
    content: '\e934';
    font-family: 'feather' !important;
    font-style: normal;
}
table.dataTable.no-footer {
    border-bottom: 0;
    margin-bottom: 0.5rem;
}

.table-dropdown.dropdown .dropdown-toggle::after {
    display: none;
}
.dataTables_wrapper .dataTables_filter input {
    border: 1px solid #e9ecef;
    padding: 1rem 0.7rem;
    height: 35px;
    box-sizing: border-box;
    border-radius: 5px;
}
.dataTables_wrapper .dataTables_length select {
    border: 1px solid #e9ecef;
    padding: 0.2rem 0.2rem;
    text-align: center;
    margin: 0 0.2rem;
}

.dataTables_wrapper .dataTables_length {
    float: right !important;
    font-size: 14px !important;
    font-size: 0.729vw !important;
}

.dataTables_wrapper .dataTables_filter {
    float: left !important;
    text-align: right !important;
}
.form-switch .form-check-input {
    width: 2.2rem;
    height: 1.4em;
}
.badge {
    font-size: 0.875rem;
}
.badge.bg-success {
    background-color: rgba(5, 163, 74, 0.2) !important;
    background-image: none;
    border-color: rgba(5, 163, 74, 0);
    color: #05a34a;
}
.badge.bg-primary {
    background-color: rgba(44, 179, 209, 0.2) !important;
    background-image: none;
    border-color: rgba(44, 179, 209, 0);
    color: #f6851f;
}

.nav-ui .nav.nav-tabs .nav-item .nav-link {
    background: #dee2e6;
    border-color: #dee2e6;
    color: var(--bs-modal-color);
    border-radius: 0;
    border-width: 1px;
}
.nav-ui .nav.nav-tabs .nav-item .nav-link.active {
    background: #fff !important;
    border-bottom-color: transparent;
    color: $primary !important;
}
.nav-ui + .tab-content .tab-pane {
    border-color: #dee2e6;
    border-style: solid;
    border-width: 0px 1px 1px 1px;
    padding: 1rem 1rem 0rem 1rem;
}
// .card-scroll .example-list {
//     padding-left: 0;
// }
.card-scroll:last-child .example-box {
    background: #f0f4f4;
}
.modal-dialog .modal-content {
    border: 0;
}
td .dropdown-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.required {
    &:after {
        content: '*';
        position: relative;
        font-size: inherit;
        color: $danger;
        padding-left: 0.25rem;
        font-weight: bold;
    }
}
.input-error {
    color: red;
    font-size: 12px;
    width: 100%;
    display: block;
    text-align: left;
}
.mat-error {
    margin-top: 4px;
    color: red;
    width: 100%;
    display: block;
    text-align: left;
    font-size: 12px;
}
.mat-form-field-infix {
    display: block;
    position: relative;
    flex: auto;
    min-width: 0;
    width: 180px;
}
.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
    caret-color: #f44336;
}
.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
    animation: cdk-text-field-autofill-end 0s 1ms;
}
.mat-form-field-label-wrapper {
    position: absolute;
    left: 0;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}
.mat-form-field-label-wrapper {
    top: -0.84375em;
    padding-top: 0.84375em;
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
    padding-bottom: 0 !important;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
    display: none !important;
}

.mat-form-field {
    display: inline-block;
    position: relative;
    text-align: left;
    font-size: inherit;
    font-weight: 400;
    line-height: 1.125;
    letter-spacing: normal;
}
.mat-form-field-flex {
    align-items: center !important;
}
.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 1.2em 0 1.2em 0;
}
.fa-eye-slash:before {
    content: '\f070';
}
.fa-eye:before {
    content: '\f06e';
}
.fa {
    font-family: var(--fa-style-family, 'Font Awesome 6 Free');
    font-weight: var(--fa-style, 900);
}
.fa,
.fa-brands,
.fa-classic,
.fa-regular,
.fa-sharp,
.fa-solid,
.fab,
.far,
.fas {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: var(--fa-display, inline-block);
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    line-height: 1;
    text-rendering: auto;
}
.toast-container {
    position: fixed;
}
.toast-container {
    --bs-toast-zindex: 1090;
    position: absolute;
    z-index: var(--bs-toast-zindex);
    width: max-content;
    max-width: 100%;
    pointer-events: none;
}
.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: $black;
}
.custom-select {
    background: #fff;
}
form label {
    margin-bottom: 0.2rem;
    font-weight: 500;
    letter-spacing: 0.02rem;
}

table.dataTable > thead > tr > th,
table.dataTable > tbody > tr > td {
    border-bottom: 1px solid #e9ecef !important;
    vertical-align: middle;
    padding: 0.8rem 0.9rem;
    font-size: 14px !important;
}
.table-hover > tbody > tr:hover > * {
    --bs-table-accent-bg: #def0f6;
}
.dataTables_paginate .btn.btn-icon {
    margin: 0.1rem;
    font-weight: 500;
    background: #fff;
    color: #0c1427;
}
.dataTables_paginate .btn.btn-icon:hover,
.dataTables_paginate .btn.btn-icon.current {
    background: #0c1427;
    border-color: #0c1427;
    color: #fff;
}

.dataTables_paginate .first .fa::after {
    content: '\e933';
    font-family: 'feather' !important;
    font-style: normal;
}
.dataTables_paginate .previous .fa::after {
    content: '\e92f';
    font-family: 'feather' !important;
    font-style: normal;
}
.dataTables_paginate .next .fa::after {
    content: '\e930';
    font-family: 'feather' !important;
    font-style: normal;
}
.dataTables_paginate .last .fa::after {
    content: '\e934';
    font-family: 'feather' !important;
    font-style: normal;
}
table.dataTable.no-footer {
    border-bottom: 0;
    margin-bottom: 0.5rem;
}

.table-dropdown.dropdown .dropdown-toggle::after {
    display: none;
}
.dataTables_wrapper .dataTables_filter input {
    border: 1px solid #e9ecef;
    padding: 1rem 0.7rem;
    height: 35px;
    box-sizing: border-box;
    border-radius: 5px;
}
.dataTables_wrapper .dataTables_length select {
    border: 1px solid #e9ecef;
    padding: 0.2rem 0.2rem;
    text-align: center;
    margin: 0 0.2rem;
}
.form-switch .form-check-input {
    width: 2.2rem;
    height: 1.4em;
}
.badge {
    font-size: 0.875rem;
}
.badge.bg-success {
    background-color: rgba(5, 163, 74, 0.2) !important;
    background-image: none;
    border-color: rgba(5, 163, 74, 0);
    color: #05a34a;
}
.badge.bg-primary {
    background-color: rgba(44, 179, 209, 0.2) !important;
    background-image: none;
    border-color: rgba(44, 179, 209, 0);
    color: #027ea8;
}

.nav-ui .nav.nav-tabs .nav-item .nav-link {
    background: #dee2e6;
    border-color: #dee2e6;
    color: var(--bs-modal-color);
    border-radius: 0;
    border-width: 1px;
}
.nav-ui .nav.nav-tabs .nav-item .nav-link.active {
    background: #fff !important;
    border-bottom-color: transparent;
    color: $primary !important;
}
.nav-ui + .tab-content .tab-pane {
    border-color: #dee2e6;
    border-style: solid;
    border-width: 0px 1px 1px 1px;
    padding: 1rem 1rem 0rem 1rem;
}
.card-scroll .example-list {
    // padding-left: 0;
    padding-top: 10px;
}
.card-scroll:last-child .example-box {
    background: #f0f4f4;
}
.modal-dialog .modal-content {
    border: 0;
}
td .dropdown-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.dropdown .table-dropdown.dropdown-toggle:after,
.btn-group .table-dropdown.dropdown-toggle:after {
    display: none;
}
.credential-requirements {
    max-width: 170px;
    margin: 0 auto;
    width: 100%;
}
.credential-requirements circle-progress {
    margin: 0 auto;
    display: table;
}

.full-bg {
    height: 100vh;
    overflow: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1;
}
.full-bg:after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background: $secondary;
    opacity: 0.85;
}
.auth-page {
    position: relative;
    z-index: 2;
}
.login-form {
    background: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
    max-width: 450px;
    margin: 1rem auto;
    width: 100%;
}
.password-eye-icon {
    position: absolute;
    top: 2.35rem;
    right: 0.8rem;
}
.navbar-dark .horizontal-menu {
    background: $secondary;
}
.navbar-dark .horizontal-menu .navbar {
    background: transparent;
}
.navbar-dark .horizontal-menu .bottom-navbar {
    background: $secondary-light;
    box-shadow: none;
}
.toast-container {
    position: fixed;
}
.btn-orange,
.swal2-container .swal2-popup .swal2-actions button.swal2-confirm {
    --bs-btn-color: #fff;
    background-color: $primary-btn;
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #f9b06d !important;
    --bs-btn-disabled-bg: #f9b06d !important;
    --bs-btn-disabled-border-color: #f9b06d !important;
    --bs-btn-hover-color: #000000;
    --bs-btn-hover-bg: #f6851f;
    --bs-btn-hover-border-color: #f6851f;
}
.nav-notifications .notificationBtn {
    background: var(--bs-link-color) !important;
    color: var(--bs-white) !important;
    border-color: var(--bs-link-color) !important;
}
.horizontal-menu .navbar .navbar-content .navbar-nav .nav-item.dropdown .dropdown-menu {
    border: 0;
}
.horizontal-menu .navbar .navbar-content {
    align-items: center;
}
.roleName h4 {
    font-size: 1rem;
    font-weight: 700;
    color: #ffff;
}
body {
    letter-spacing: 0.03rem;
}
.breadcrumb .breadcrumb-item {
    font-size: 16px;
}
.nav-item .breadcrumb .breadcrumb-item a {
    color: #103558;
}
table.dataTable > thead > tr > th {
    color: #103558;
}
table.dataTable tbody td {
    color: #6d7382;
}
.modal {
    background: rgba($secondary, 0.5);
}

.image-input {
    position: relative;
    display: inline-block;
    border-radius: 10%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 135px;
    height: 135px;
    margin: 5px auto 0;

    // Wrapper
    .image-input-wrapper {
        width: 135px;
        height: 135px;
        border-radius: 10%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    // Change Button
    [data-action='change'] {
        cursor: pointer;
        position: absolute;
        right: -10px;
        top: -10px;

        input {
            width: 0 !important;
            height: 0 !important;
            overflow: hidden;
            opacity: 0;
        }
    }

    // Cancel & Remove Buttons
    [data-action='cancel'],
    [data-action='remove'] {
        position: absolute;
        right: -10px;
        bottom: -5px;
    }

    [data-action='cancel'] {
        display: none;
    }

    // Input Changed State
    &.image-input-changed {
        [data-action='cancel'] {
            display: flex;
        }

        [data-action='remove'] {
            display: none;
        }
    }

    // Input Empty State
    &.image-input-empty {
        [data-action='remove'],
        [data-action='cancel'] {
            display: none;
        }
    }

    // Circle style
    &.image-input-circle {
        border-radius: 50%;

        // Wrapper
        .image-input-wrapper {
            border-radius: 50%;
        }

        // Change Control
        [data-action='change'] {
            right: 5px;
            top: 5px;
        }

        // Cancel & Remove Buttons
        [data-action='cancel'],
        [data-action='remove'] {
            right: 5px;
            bottom: 5px;
        }
    }

    // Bordered style
    &.image-input-outline {
        .image-input-wrapper {
            border: 3px solid $gray-200;
            // box-shadow: $box-shadow;
        }
    }
}
.edit-profile-image {
    padding: 7px 18px;
    font-size: 13px;
    width: 133px;
    margin: 8px auto 0;
    display: block;
}
.table td img {
    width: 50px;
    height: 50px;
    border-radius: 15%;
    object-fit: cover;
}
.apx-chart {
    color: #027ea8;
}
.mat-form-field-flex {
    height: 43px;
}
.mat-calendar-body-selected {
    background-color: #2596be;
}
.mat-toolbar.mat-primary {
    background-color: #2596be;
}
.mat-mini-fab.mat-primary {
    background-color: #2596be;
}
.mat-button.mat-primary {
    color: #2596be;
}
.section {
    position: absolute;
    top: 10px;
    left: -4px;
    z-index: 9;
}
.title {
    padding: 5px 20px;
    background-color: $primary;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    color: white;
    position: relative;
    filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.2));
    display: inline-block;
}
.title::after {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    background-color: $primary;
    opacity: 0.4;
    left: 0;
    bottom: -1rem;
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 0%);
}
.card-body > .section {
    position: relative;
    margin-left: -2.5rem;
    margin-bottom: 2rem;
    top: 0;
    left: 0;
    margin-top: -0.5rem;
}
.img-cover {
    object-fit: cover;
    object-position: center center;
}
body {
    background: #e6e7e8;
}

.cdk-overlay-container {
    position: fixed;
    z-index: 1000000;
}
.dataTable-column-align .symbol-label img {
    background: #f0f4f8;
    display: block;
}
.nav-ui-design .nav.nav-tabs .nav-item .nav-link {
    margin: 0;
    border: 0;
    background: transparent;
    font-weight: 500;
    font-size: 0.85rem;
    letter-spacing: 0.03rem;
    color: $secondary-light;
}
.nav-ui-design .nav.nav-tabs .nav-item .nav-link.active {
    background: $white;
    color: $secondary;
}
.calendar-modal-content .card .card-body {
    padding: 0;
}
.calendar-modal-content .card {
    margin: 0 !important;
}

.shift-img {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin: 1px;
}
.mbsc-eventcalendar .mbsc-calendar-header,
.mbsc-eventcalendar .mbsc-calendar-week-days {
    background: #fff;
}
.mbsc-calendar-button.mbsc-button {
    color: $primary;
}
.mbsc-calendar-width-md .mbsc-calendar-title {
    font-size: 1rem;
    font-weight: 600;
    color: $secondary;
}
.mbsc-textfield-box,
.mbsc-textfield-outline,
.mbsc-event.mbsc-list-item::after,
.mbsc-event.mbsc-list-item::before {
    border-color: #e7ecf0;
}
.mbsc-textfield-inner {
    max-width: 500px;
    margin: 0 auto;
    width: 100%;
}
.mbsc-font-icon {
    left: 0.5rem;
    font-size: 1.3rem;
    top: 0.45rem;
}
.mbsc-calendar-button.mbsc-button.mbsc-icon-button,
.mbsc-calendar-button.mbsc-button.mbsc-calendar-button-today {
    font-size: 0.9rem;
    font-weight: bold;
    border-radius: 0;
}
.mbsc-eventcalendar .mbsc-calendar-day.mbsc-disabled {
    background: #f0f3fa;
}
.mbsc-calendar-width-md .mbsc-calendar-week-day {
    font-weight: 600;
    color: $text-muted;
    font-size: 0.85rem;
    border-color: #e7ecf0 !important;
    border-bottom: 0;
}
.mbsc-calendar-cell,
.mbsc-calendar-day::after,
.mbsc-calendar-header,
.mbsc-calendar-wrapper {
    border-color: #e7ecf0;
}
.mbsc-calendar-body-inner {
    border: 1px solid #e7ecf0;
    border-bottom: 0;
}
.mbsc-event.mbsc-list-item.mbsc-hover::before {
    background-color: #e7ecf0;
}

.mbsc-popup-pointer .mbsc-popup .mbsc-popup-body-round {
    border-radius: 0.4rem;
    box-shadow: 0 0 15px 0 rgba(183, 192, 206, 0.2);
    -webkit-box-shadow: 0 0 15px 0 rgba(183, 192, 206, 0.2);
    -moz-box-shadow: 0 0 15px 0 rgba(183, 192, 206, 0.2);
    -ms-box-shadow: 0 0 15px 0 rgba(183, 192, 206, 0.2);
}
/* .mbsc-calendar-cell .mbsc-calendar-label { min-height: 1.8em; line-height: 1.8em; margin: 0 0.5em 0.2em 0.6em; padding: 0.4em 0.4em 0.4em 1.1em; height: auto; }*/
.mbsc-calendar-width-md .mbsc-calendar-day-text {
    font-size: 0.9rem;
    font-weight: 600;
}
.mbsc-calendar-width-md .mbsc-calendar-day-labels .mbsc-calendar-day-inner {
    min-height: 5.55em;
}
.mbsc-event-content {
    display: flex;
    align-items: center;
}
.mbsc-event.mbsc-list-item {
    color: $secondary;
    font-size: 0.85rem;
}
.mbsc-schedule-date-header {
    background: #fff;
    border-color: #e7ecf0;
}
.md-meeting-planner-cont {
    box-shadow: none;
    border-radius: 3px;
}
.md-meeting-planner-title {
    padding-top: 0;
}
.mbsc-timeline-column,
.mbsc-timeline-row,
.mbsc-timeline-resource,
.mbsc-timeline-resource-header-cont,
.mbsc-timeline-header-date,
.mbsc-timeline-slot-header,
.mbsc-timeline-slots {
    border-color: #e7ecf0;
}
div .calendar-view .mbsc-schedule-all-day-item.mbsc-ltr::after,
div .calendar-view .mbsc-timeline-day-border.mbsc-ltr::after {
    border-right: 0;
}
div .calendar-view .mbsc-timeline-header-date {
    color: #7987a1;
    font-weight: 600;
    padding: 0.3rem 1rem;
}
div .calendar-view .mbsc-timeline-header-active::after {
    background-color: $primary;
}
.employee-shifts-cont .employee-shifts-avatar {
    height: 30px;
    width: 30px;
    border-radius: 50px;
    left: 1rem;
}
div .employee-shifts-cont .employee-shifts-name {
    font-size: 0.9rem;
    font-weight: 700;
    color: $secondary;
}
.employee-shifts-cont .employee-shifts-title {
    color: $text-muted;
}
div .calendar-view .mbsc-selected .mbsc-calendar-cell-text {
    background: $primary;
    border-color: $primary;
}
div .calendar-view .mbsc-hover .mbsc-calendar-cell-text {
    background: rgba($primary, 0.2);
    border-color: rgba($primary, 0.05);
    color: $primary;
}
div .calendar-view .mbsc-calendar-cell {
    color: $secondary;
}
div .calendar-view .md-meeting-planner-title {
    font-size: 0.85rem;
    font-weight: 600;
    padding: 0.3rem;
}

@media (max-width: 767px) {
    div .calendar-view .mbsc-calendar-controls .md-seach-header-bar {
        width: 100%;
    }
    div .calendar-view .md-seach-header-bar .mbsc-textfield-wrapper.mbsc-form-control-wrapper {
        width: 100%;
    }
}

.primary-color {
    color: $primary;
}
.fs-7 {
    font-size: 12px;
}
.job-list-view .wd-70,
.job-list-view .wd-70 img {
    width: 100%;
    height: 100%;
}
.title-tag {
    position: absolute;
    top: 10px;
    left: -15px;
    z-index: 1;
}
.resume-col .card .card-body:last-child {
    border-bottom: none !important;
}
.w-40 {
    width: 40px;
}
.w-60 {
    width: 60px;
}
.expertise .border-dark {
    border-color: #babcc1 !important;
}

// @media (max-width: 1199px) {
//     .nav-link {
//         padding: 12px 18px !important;
//     }
// }
@media (max-width: 575px) {
    .app-img {
        max-width: 100% !important;
    }
}

.shift-img {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin: 1px;
}
.mat-checkbox-layout .mat-checkbox-label {
    display: flex;
    align-items: center;
}

.primary-color {
    color: $primary;
}
.fs-7 {
    font-size: 12px;
}
.job-list-view .wd-70,
.job-list-view .wd-70 img {
    width: 100%;
    height: 100%;
}
@media (max-width: 991px) {
    .job-list-view .top-0 {
        top: calc(50% - 15px) !important;
    }
}

.calendar-modal-content {
    background: #e7ecf4;
}

nav .pagination .page-item .page-link {
    height: 45px;
    width: 45px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1rem;
    color: rgba($secondary, 0.6);
    border-color: rgba($secondary, 0.1);
}

nav .pagination .page-item.active .page-link {
    background: $primary;
    color: $white;
    border-color: $primary;
}
nav .pagination .page-item .page-link:hover {
    border-color: rgba($secondary, 0.1);
    background: rgba($secondary, 0.1);
    color: rgba($secondary, 0.6);
}
.ng-dropdown-panel {
    display: contents !important;
}

// .mbsc-calendar-header-today  {
//   display: none !important;
// }

.inner-table h4 {
    font-size: 1rem;
}
.dropdown-box {
    position: relative;
    z-index: 5;
}
.dropdown-box .ng-dropdown-panel .ng-dropdown-panel-items {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background-color: $white;
    border: 1px solid #e9ecef;
    border-top: 0;
    border-radius: 0.25rem;
}
.mat-accordion .mat-expansion-panel {
    box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
    -webkit-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
    -moz-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
    -ms-box-shadow: 0 0 10px 0 rgba(183, 192, 206, 0.2);
    border-radius: 0.25rem !important;
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
    background-color: $white !important;
}
.card-accordion .mat-expansion-panel.mat-expanded {
    overflow: inherit;
}

.timesheet card {
    width: min-content !important;
}

.timesheet card-body {
    width: max-content !important;
}

.line-break-cell {
    max-width: 275px; /* Adjust the max-width based on your requirements */
    white-space: inherit !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.centered-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
.icon-cog {
    color: #6f2da8;
}

.fa-angle-right::before,
.fa-angle-double-right::before,
.fa-angle-left::before,
.fa-angle-double-left::before {
    content: none;
}

//set font-family as red hat display

body {
    font-family: 'Red Hat Display', sans-serif;
    font-size: 17px; /* Adjust the font size as needed */
    font-size: 0.892vw;
    font-weight: 500; /* Regular */
    /* Add any other font-related styles here */
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Red Hat Display', sans-serif;
    font-weight: 700; /* Bold */
    /* Add any other heading styles here */
}

//page background color

.page-wrapper {
    // background-color: #e6e7e8 !important;
    // padding-left: 250px;
    display: flex;
    flex-direction: row;
    justify-content: end;
}
.page-wrapper-main {
    padding-left: 0px;
    transition: 0.4s;
    margin: 20px 60px;
}

.modal-title {
    color: $primary;
}

.logo {
    height: auto;
    width: 150px;
}
.horizontal-menu .bottom-navbar .page-navigation > .nav-item .submenu ul li a:before {
    display: none;
}
.transparent-view-btn {
    border: none;
    background: transparent;
}
// .sidebar-stop .sidebar{
//     position: fixed !important;
//     top: 70px !important;
//     left: 0;
// }
.sidebar-stop .sidebar {
    top: 70px !important;
}
.fixed-on-scroll .horizontal-menu .bottom-navbar {
    border-bottom: none;
}
.page-wrapper-main .sidebar .slidbarBtn {
    right: -25px;
}
.page-wrapper-main .sidebar .slidbarBtn span {
    transform: rotate(180deg);
}

//nz-tree
.ant-tree .ant-tree-node-content-wrapper {
    padding: 6px 6px !important;
}

.ant-tree {
    background: none !important;
    color: white !important;
    font-weight: bold !important;
}

.ant-tree-show-line .ant-tree-switcher {
    background: none !important;
}

/* Remove the default indent background */
.ant-tree-indent,
.ant-tree-indent-unit {
    background: none !important;
}

/* Create a dotted line for each indent unit */
.ant-tree-indent-unit {
    position: relative;
}

.ant-tree-indent-unit::after {
    content: '';
    position: absolute;
    left: 50%;
    width: 1px;
    height: 100%;
    background: rgba(255, 255, 255, 0.3); /* Adjust color and opacity as needed */
    border-left: 1px dashed #fff; /* Dashed line */
    box-sizing: border-box;
}

/* Style for the active state of NZ-Tree */
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    color: #ffffff;
    background: #44b5db !important;
    width: 100%;
    padding-right: 0px !important;
}

.inner-tree {
    .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
        color: #ffffff;
        background: #810bfe !important;
        width: 100%;
        padding-right: 0px !important;
    }
}

.ant-tree .ant-tree-node-content-wrapper:hover {
    background: #44b5db !important;
    width: 100%;
}
.ant-tree .ant-tree-node-content-wrapper:hover span {
    color: #ffffff !important;
}

/* Add your own color styling */
.menu-title:hover {
    color: $primary-btn; /* Change the color as per your requirement */
}
//popup

/* Initially, the modal should be off the screen on the right */
/* When the modal is active, this will be overridden by the 'show' class */

.my-custom-modal .modal-dialog {
    position: fixed;
    margin: auto;
    width: auto !important; /* or whatever width you want */
    height: auto !important;
    right: 0;
    bottom: 0;
    top: 0;
    animation: slideInFromRight 0.3s ease forwards;
}

/* Slide-in effect when the modal is opened */
.my-custom-modal.show .modal-dialog {
    transform: translateX(0%); /* Slide in to the view */
}

.my-custom-modal .modal-content {
    border-radius: 0; /* No rounded corners */
    border: 0; /* No border */
}

/* Styling for the modal footer, ensuring it sticks to the bottom */
.my-custom-modal .modal-content {
    display: flex;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.my-custom-modal .modal-body {
    flex-grow: 1;
    overflow-y: auto; /* Scrollable content */
}

// .popup-footer {
//     margin-top: 690px !important;
// }

@keyframes slideInFromRight {
    from {
        transform: translateX(100%); /* Start off-screen to the right */
        opacity: 0; /* Start from transparent if you want a fade-in effect */
    }
    to {
        transform: translateX(0); /* End at its final position */
        opacity: 1; /* Fully visible */
    }
}

.my-custom-modal.closing .modal-dialog {
    transform: translateX(100%); /* Moves the modal to the right */
    opacity: 0; /* Fades the modal out */
    transition: transform 0.3s, opacity 0.3s; /* Animates the properties over 0.3s */
}

//

.my-custom-modal:focus-within .modal-dialog {
    /* This assumes the modal or a button within it can be focused */
    transform: translateX(100%);
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
}

body:not(.modal-open) .my-custom-modal .modal-dialog {
    transform: translateX(100%);
    opacity: 0;
    transition: transform 0.5s, opacity 0.5s;
}

// .my-custom-modal .modal-content::-webkit-scrollbar {
//     display: none !important;
// }

//
.modal-content.fixwidth {
    width: 500px !important;
}

.modal-content {
    background-color: #e6e7e8;
}

.form-label-right {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.btn-border {
    border: none;
    background: none;
}

.dataTables_wrapper .dataTables_filter input {
    display: block;
    padding: 0.65rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    border-radius: 0.42rem;
    box-shadow: none;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    background: white;
    border: 1px solid black;
    width: 700px !important;
    margin-left: 0;
}
.dataTables_paginate .btn.btn-icon {
    width: 45px !important;
}
input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: searchfield-cancel-button;
    cursor: pointer;
}

.icon-color {
    color: #c9c9c9;
}

//

app-datatable .card .card-body {
    background-color: #e6e7e8;
    border: none;
    padding: 0;
}

.dataTables_filter {
    margin-bottom: 20px;
    background-color: white !important;
}

.dataTables_length select {
    background-color: white !important;
}

.table {
    background-color: white !important;
}

.table thead {
    background: #bbbcbc;
    font-size: 14px;
    color: black;
}

.table thead th {
    color: #051920;
    text-transform: none;
}

.table th,
.table .td {
    height: 36px;
}
.dataTables_wrapper .dataTables_filter {
    border-radius: 0.42rem !important;
}
.table.dataTable > thead > tr > th {
    color: #051920 !important;
    height: auto;
}

.logo-input {
    position: relative;
    display: inline-block;
    border-radius: 10%;
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 90px;
    height: 90px;
    margin: 5px auto 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

/* Added By Nilesh */
.contact-scrollbar {
    overflow: auto;
    max-height: 56.5vh;
    min-height: 30vh;
}
.new_nav .nav-tabs {
    border: 0;
    margin: 0;
    background: #fff;
    white-space: nowrap;
    overflow-x: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-y: hidden;
}
.new_nav .nav.nav-tabs .nav-item .nav-link {
    border: 0;
    background: #fff;
    border-radius: 0;
    font-weight: 600;
    color: #027ea8;
    padding: 0.7rem 0.7rem;
    font-size: 0.892vw;
}
.new_nav .nav.nav-tabs .nav-item .nav-link.active {
    color: #fff;
    background: #027ea8;
}
.new_nav .nav.nav-tabs .nav-item {
    margin-right: 0.5vw;
}

@media only screen and (max-width: 1199px) {
    body {
        font-size: 1.092vw;
    }
}
@media only screen and (max-width: 991px) {
    body {
        font-size: 1.292vw;
    }
}
@media only screen and (max-width: 767px) {
    body {
        font-size: 2.2vw;
    }
}
@media only screen and (max-width: 576px) {
}

.healthcare-panel-list .ant-tree-indent-unit::after {
    background: #051920;
    top: -10px;
    bottom: -12px;
    height: auto;
}
.healthcare-panel-list .ant-tree .ant-tree-treenode {
    padding: 0;
}
.healthcare-panel-list .ant-tree .ant-tree-treenode .ant-tree-switcher:after {
    content: '';
    border-top: 1px dashed #051920;
    position: absolute;
    top: 15px;
    right: 100%;
    margin: auto;
    height: 2px;
    width: 11px;
}
.healthcare-panel-list .ant-tree .ant-tree-treenode:first-child .ant-tree-switcher:after {
    display: none;
}
.healthcare-panel-list .ant-tree .ant-tree-treenode.ant-tree-treenode-selected {
    /*background: #D9DBDC;*/
    position: relative;
    z-index: 1;
}
.healthcare-panel-list .ant-tree .ant-tree-treenode.ant-tree-treenode-selected .ant-tree-node-selected,
.healthcare-panel-list .ant-tree .ant-tree-node-content-wrapper:hover {
    background: transparent !important;
    color: #000 !important;
}
.healthcare-panel-list .ant-tree .ant-tree-node-content-wrapper:hover span {
    color: #000 !important;
}
.healthcare-panel-list .anticon-minus-square svg,
.healthcare-panel-list .anticon-plus-square svg {
    border: 1px solid #000;
    border-radius: 50px;
}
.healthcare-panel-list .anticon-plus-square svg {
    background: #f6851f;
}
.healthcare-panel-list .anticon-minus-square svg path:last-child,
.healthcare-panel-list .anticon-plus-square svg path:last-child {
    opacity: 0;
}
.healthcare-panel-list .ant-tree .ant-tree-treenode.ant-tree-treenode-selected .ant-tree-node-selected:after {
    content: '';
    background: #d9dbdc;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: -30px;
    z-index: -9;
}
.healthcare-panel-list .ant-tree-show-line .ant-tree-switcher {
    z-index: 2;
}
.healthcare-panel-list .anticon {
    position: relative;
    top: 4px;
    vertical-align: middle;
}
.healthcare-panel-list .ant-tree-show-line .ant-tree-switcher.ant-tree-switcher-noop {
    width: 0;
}
.healthcare-panel-list .ant-tree-show-line .ant-tree-switcher.ant-tree-switcher-noop .anticon {
    display: none;
}
.sidebar .ant-tree-show-line .ant-tree-switcher.ant-tree-switcher-noop .anticon {
    display: none;
}
.sidebar .ant-tree-show-line .ant-tree-switcher.ant-tree-switcher-noop {
    width: 0;
}
.sidebar .ant-tree-indent-unit::after {
    top: -10px;
    bottom: -12px;
    height: auto;
}
.sidebar .ant-tree-show-line .ant-tree-indent-unit::before {
    right: 11px;
    bottom: -2px;
    border-right: 1px dashed #fff;
}
.sidebar .ant-tree .anticon-minus-square svg,
.sidebar .ant-tree .anticon-plus-square svg {
    border: 1px solid #fff;
    border-radius: 50px;
}
.sidebar .ant-tree .anticon-minus-square svg path:last-child,
.sidebar .ant-tree .anticon-plus-square svg path:last-child {
    opacity: 0;
}
.sidebar .ant-tree .ant-tree-treenode .ant-tree-switcher:after {
    content: '';
    border-top: 1px dashed #fff;
    position: absolute;
    top: 15px;
    right: 100%;
    margin: auto;
    height: 2px;
    width: 11px;
}
.sidebar .ant-tree-treenode:last-child .ant-tree-indent-unit::after {
    bottom: 0;
    height: 26px;
}
.healthcare-panel-list .ant-tree .ant-tree-treenode:last-child .ant-tree-indent-unit::after {
    bottom: 0;
    height: 26px;
}

.healthcare-panel-list .ant-tree-show-line:last-child .ant-tree-list .ant-tree-indent-unit:first-child:after {
    bottom: 0;
    top: -7px; /*height: 20px;*/
}
.healthcare-panel-list
    .ant-tree-show-line:last-child
    .ant-tree-list
    .ant-tree-treenode:nth-child(2n)
    ~ .ant-tree-treenode
    .ant-tree-indent-unit:first-child {
    /*opacity: 0;*/
}
.healthcare-panel-list .ant-tree-show-line .ant-tree-indent-unit::before {
    border-color: transparent;
}
.healthcare-panel-list .ant-tree-treenode-selected .ant-tree-indent-unit::after {
    border-color: #d9dbdc;
}
.healthcare-panel-list
    .ant-tree
    .ant-tree-treenode.ant-tree-treenode-selected.ant-tree-treenode-switcher-close
    .ant-tree-node-selected:after {
    background: #fff;
}
.contact-data-info-main {
    position: relative;
}
.contact-data-info {
    padding-right: 1.5rem;
}
.contact-data-icon {
    position: absolute;
    top: auto;
    right: 0.8rem;
    bottom: 0.4rem;
    margin: auto;
    z-index: 5;
    font-size: 1.4rem;
    font-size: 1.167vw;
}
.contact-data-icon .btn-icon {
    cursor: pointer;
    margin: 0.5rem 0;
    color: #103558;
}
.no-data-available {
    padding: 2rem;
    text-align: center;
    margin-top: 1rem;
}
.no-data-available figure {
    max-width: 115px;
    width: 100%;
    margin: 0 auto 1.5rem;
}
.no-data-available figure img {
    width: 100%;
    height: auto;
}
.no-data-available h5 {
    font-size: 15px;
    color: #103558;
    font-weight: 700;
    max-width: 255px;
    margin: 0 auto;
    width: 100%;
}
.card-body .tab-pane .modal-footer {
    background: #fefcf3;
    padding: 1.2rem;
    margin: 0 -1rem -1rem;
    border-radius: 0 0 0.25rem 0.25rem;
}

.tab-pane .angular-bootstrap-table {
    background: #fff;
}
.tab-pane .angular-bootstrap-table .dataTables_length {
    display: none;
}
.tab-pane .angular-bootstrap-table .dataTables_filter {
    width: 100%;
}
.tab-pane .angular-bootstrap-table .dataTables_filter label {
    display: block;
    width: 100%;
    position: relative;
}
.tab-pane .angular-bootstrap-table .dataTables_filter label:after {
    content: '\e9cc';
    font-family: 'feather';
    font-style: normal;
    font-weight: bold;
    position: absolute;
    bottom: 0.3rem;
    right: 1rem;
}
.tab-pane .angular-bootstrap-table .dataTables_filter input {
    min-width: 100%;
    border-color: #103558;
    box-shadow: none;
    outline: none;
    position: relative;
}
.tab-pane .angular-bootstrap-table .dataTables_filter input::-webkit-search-cancel-button {
    position: relative;
    right: 1.5rem;
}

.tab-pane .angular-bootstrap-table .dataTables_filter:before {
    content: 'Search';
    width: 100%;
    display: block;
    font-size: 14px;
    font-weight: 700;
    text-align: left;
    margin-bottom: 0.4rem;
}
.tab-pane .angular-bootstrap-table table.dataTable > thead > tr > th,
.tab-pane .angular-bootstrap-table table.dataTable > tbody > tr > td {
    padding: 0.2rem 0.9rem;
}
.tab-pane .angular-bootstrap-table .dataTables_wrapper .dataTables_info {
    display: none;
}
//pagination css {heathcare)

// .tab-pane .angular-bootstrap-table .dataTables_wrapper .dataTables_paginate {
//     float: left;
//     border: 1px solid #027ea8;
//     display: flex;
//     padding: 0;
//     border-radius: 0.25rem;
// }
// .tab-pane .angular-bootstrap-table .dataTables_wrapper .dataTables_paginate a {
//     margin: -1px;
//     border: 1px solid transparent;
//     border-radius: 0;
//     font-size: 14px;
//     color: #103558;
//     display: flex;
//     position: relative;
//     z-index: 0;
//     width: auto !important;
//     padding: 0 1rem;
// }
// .tab-pane .angular-bootstrap-table .dataTables_wrapper .dataTables_paginate span {
//     display: flex;
//     position: relative;
//     z-index: 2;
// }
// .tab-pane .angular-bootstrap-table .dataTables_wrapper .dataTables_paginate span a.current {
//     background: #fff;
//     border: 1px solid #051920;
// }

// .tab-pane .angular-bootstrap-table .dataTables_wrapper .dataTables_paginate a.previous em:before {
//     content: '\e92f';
//     font-family: 'feather' !important;
//     font-style: normal;
//     position: relative;
//     top: 1px;
//     color: #868686;
// }
// .tab-pane .angular-bootstrap-table .dataTables_wrapper .dataTables_paginate a.previous em:after {
//     content: 'Previous';
//     font-family: 'Red Hat Display', sans-serif !important;
//     font-weight: 400;
//     font-size: 14px;
//     color: #868686;
//     margin-left: 0.2rem;
// }
// .tab-pane .angular-bootstrap-table .dataTables_wrapper .dataTables_paginate a.first em:before {
//     content: '\e933';
//     font-family: 'feather' !important;
//     font-style: normal;
//     position: relative;
//     top: 1px;
//     color: #868686;
// }
// .tab-pane .angular-bootstrap-table .dataTables_wrapper .dataTables_paginate a.first em:after {
//     content: 'First';
//     font-family: 'Red Hat Display', sans-serif !important;
//     font-weight: 400;
//     font-size: 14px;
//     color: #868686;
//     margin-left: 0.2rem;
// }
// .tab-pane .angular-bootstrap-table .dataTables_wrapper .dataTables_paginate a.next em:before {
//     content: 'Next';
//     font-family: 'Red Hat Display', sans-serif !important;
//     font-weight: 400;
//     font-size: 14px;
//     color: #868686;
//     margin-right: 0.2rem;
// }
// .tab-pane .angular-bootstrap-table .dataTables_wrapper .dataTables_paginate a.last em:before {
//     content: 'Last';
//     font-family: 'Red Hat Display', sans-serif !important;
//     font-weight: 400;
//     font-size: 14px;
//     color: #868686;
//     margin-right: 0.2rem;
// }
// .tab-pane .angular-bootstrap-table .dataTables_wrapper .dataTables_paginate a.next em::after,
// .tab-pane .angular-bootstrap-table .dataTables_wrapper .dataTables_paginate a.last em::after {
//     position: relative;
//     top: 1px;
//     color: #868686;
// }

//end

.form-label,
form label {
    font-weight: 700;
    letter-spacing: 0.02rem;
    font-size: 14px;
}
.modal-footer.filter-create-btn .grid-margin {
    margin-bottom: 0 !important;
}

.card-context {
    position: relative;
}
.card-context .modal-footer {
    position: sticky;
    bottom: 0;
    z-index: 5;
}
.card-context .tab-pane {
    height: 72.2vh;
    overflow: auto;
}
.form-control,
.form-control:focus,
.ng-select .ng-select-container,
.ng-select .ng-select-container:focus {
    border: 1px solid #103558;
    border-radius: 6px;
    color: #103558;
    background-color: #fff;
}
.ng-select.ng-select-disabled > .ng-select-container {
    background-color: #fff;
    color: #027ea8;
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 10px;
    height: 6px;
}

/* Thumb (the draggable part) */
::-webkit-scrollbar-thumb {
    background-color: #44b5db;
    border-radius: 40px; /* Makes the thumb rounded */
}

/* Track (the background behind the thumb) */
::-webkit-scrollbar-track {
    background-color: #b8e9f9;
    border-radius: 40px; /* Rounds the track */
}

/* Corner (where vertical and horizontal scrollbars meet) */
::-webkit-scrollbar-corner {
    background-color: #b8e9f9;
    border-radius: 40px;
}

.address-table {
    max-height: 22vh;
    overflow: auto;
    font-size: 15px;
}
.contact-data-info p {
    font-size: 0.8291666667vw;
}
.contact-profile-box {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
.contact-data-info-main {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
.healthcare-panel-list {
    max-height: 82vh;
    overflow: auto;
}

.form-check:not(.form-switch) input.form-check-input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    z-index: 2;
}

.form-check:not(.form-switch) label:before {
    content: '';
    width: 27px;
    height: 27px;
    border: 2px solid #103558;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50px;
}

.form-check:not(.form-switch) {
    position: relative;
    padding-left: 2.3rem;
}
.form-check:not(.form-switch) label:after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    height: 17px;
    width: 17px;
    background: #103558;
    border-radius: 50px;
    transition: 0.35s all;
    opacity: 0;
    visibility: hidden;
}
.form-check:not(.form-switch) .form-check-input:checked + label:after {
    opacity: 1;
    visibility: visible;
}
.ant-tree .ant-tree-node-content-wrapper {
    line-height: 1.25;
}
@media only screen and (max-width: 1400px) {
    .new_nav .nav.nav-tabs .nav-item .nav-link {
        padding: 0.5rem 0.5rem;
    }
    .new_nav .nav.nav-tabs .nav-item {
        margin-right: 0.3vw;
    }
    .new_nav .nav.nav-tabs .nav-item:last-child {
        margin-right: 0;
    }
    .form-control,
    .dataTable-top .dataTable-search .dataTable-input,
    quill-editor .ql-container .ql-tooltip input[type='text'],
    .ng-select .ng-select-container {
        padding: 0.369rem 0.6rem;
        font-size: 0.775rem;
        height: 35px;
    }
    .ng-select.ng-select-single .ng-select-container {
        height: 32px;
        min-height: 32px;
        padding-left: 0;
    }
    .p-calendar {
        height: 32px !important;
    }
    .address-table {
        font-size: 14px;
    }
    .table > :not(caption) > * > * {
        padding: 0.65rem 0.75rem;
    }
    table.dataTable > thead > tr > th,
    table.dataTable > tbody > tr > td {
        padding: 0.5rem 0.9rem;
    }
    .dataTables_wrapper .dataTables_filter {
        max-width: 700px;
        width: 78%;
    }
    .dataTables_wrapper .dataTables_filter label {
        width: 100%;
    }
    .dataTables_wrapper .dataTables_filter input {
        padding: 0.369rem 0.6rem;
        font-size: 0.775rem;
        height: 32px;
        width: 100% !important;
    }
    table.dataTable > tbody > tr > td i {
        font-size: 75%;
    }
    h4,
    .h4 {
        font-size: 1.05rem;
    }
    .dataTables_paginate .btn.btn-icon,
    .dataTables_paginate .swal2-container .swal2-popup .swal2-actions button.btn-icon,
    .swal2-container .swal2-popup .swal2-actions .dataTables_paginate button.btn-icon {
        width: 35px !important;
        height: 35px;
    }
    .tab-pane .angular-bootstrap-table table.dataTable > thead > tr > th,
    .tab-pane .angular-bootstrap-table table.dataTable > tbody > tr > td {
        padding: 0.6rem 0.9rem;
        height: auto;
    }
    h5,
    .h5 {
        font-size: 0.9rem;
    }
    .form-label,
    form label {
        font-size: 13px;
    }
    .tab-pane .angular-bootstrap-table .dataTables_filter label:after {
        bottom: 0.4rem;
        right: 0.8rem;
    }
    .no-data-available {
        padding: 1rem;
        margin-top: 0.5rem;
    }
    .no-data-available figure {
        max-width: 95px;
        margin: 0 auto 1rem;
    }
    .no-data-available h5,
    .no-data-available .h5 {
        font-size: 13px;
    }
    .modal-footer .btn {
        font-size: 0.9vw;
        padding: 0.5vw 0.9vw;
    }
    .ant-tree,
    .ant-tree-checkbox-group {
        font-size: 13px;
    }
}

@media only screen and (max-width: 1199px) {
    table.dataTable > thead > tr > th,
    table.dataTable > tbody > tr > td {
        font-size: 13px !important;
        line-height: 1;
    }
    table.dataTable > tbody > tr > td .btn-icon.btn-sm {
        height: 30px;
        width: 30px;
    }
    .btn {
        --bs-btn-font-size: 0.675rem;
    }
}

.tooltip-inner {
    font-size: 0.633333vw;
}
.tooltip-inner p {
    font-size: inherit;
}
.job-top-box figure {
    margin: 0 0.8rem 0 0;
    width: 2.8645833333333335vw;
    min-width: 2.8645833333333335vw;
    max-width: 2.8645833333333335vw;
}
.job-top-box figure img {
    width: 100%;
    height: auto;
}
.job-top-box {
    padding-right: 2.5rem;
    margin-bottom: 0.9rem;
}
.job-top-info {
    width: 100%;
    color: #103558;
    font-size: 0.7291666666666666vw;
}
.job-top-info h5 {
    font-size: 1.0416666666666667vw;
}
.btn.summary-btn {
    font-size: 0.7291666666666666vw;
    min-width: 5.208333333333333vw;
    width: 5.208333333333333vw;
    max-width: 5.208333333333333vw;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
}
.bg-draft {
    background: #e6e7e8;
    color: #027ea8;
}
.bg-cna {
    background: #488862;
    color: #fff;
}
.bg-lpn {
    background: #52acff;
    color: #fff;
}
.bg-open {
    background: #69c68e;
    color: #fff;
}
.bg-rn {
    background: #c150e9;
    color: #fff;
}
.bg-canceled {
    background: #ff5045;
    color: #fff;
}
.bg-closed {
    background: #f6851f;
    color: #fff;
}
.bg-pending {
    background: #f8ed92;
    color: #000;
}
.bg-cornflower-blue {
    background: #7291ff;
    color: #fff;
}
.bg-purple {
    background: #e646ea;
    color: #fff;
}
.label-list .badge {
    margin: 0.20833333333333334vw 0.4166666666666667vw 0.20833333333333334vw 0;
    font-size: 0.7291666666666666vw;
}
.label-list .badge:first-child {
    min-width: 4.6875vw;
}
.job-bottom-box h5 {
    font-size: 0.7291666666666666vw;
    color: #103558;
    margin-top: 0.4166666666666667vw;
}
.available-job-list {
    font-size: 0.8333333333333334vw;
}
.available-job-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.available-job-list li span {
    height: 0.5729166666666666vw;
    width: 0.5729166666666666vw;
    min-width: 0.5729166666666666vw;
    margin: 0.3125vw 0.4166666666666667vw 0 0;
}
.available-job-list li {
    align-items: self-start;
    line-height: 1.25;
}

.filter-search .btn-search-ico {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 1px;
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    color: #103558;
    font-size: 1.2rem;
    width: 37px;
    outline: none;
    box-shadow: none;
}
.filter-search .form-control {
    padding-right: 2.5rem;
}
.custom-dropdown-box .ng-select-container {
    min-height: 38px;
    height: 38px;
}
.filter-search .clear-btn {
    position: absolute;
    right: 35px; /* Adjust based on the size of your search button */
    top: 0;
    border: none;
    background: transparent;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
}

.filter-top-box {
    position: relative;
    padding-right: 2.604vw;
}
.filter-icon {
    position: absolute;
    top: 0.8rem;
    right: 0;
    width: 2.083vw;
    cursor: pointer;
}
.filter-icon svg {
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 1400px) {
    .custom-dropdown-box .ng-select-container {
        min-height: 35px;
        height: 35px;
    }
    .custom-dropdown-box .ng-select-container .ng-value-container {
        padding-top: 0 !important;
        padding-left: 0 !important;
    }
    .custom-dropdown-box .ng-select-container .ng-value-container .ng-placeholder {
        top: 6px !important;
        left: 0.5rem !important;
        padding-left: 0.5rem;
    }
    // .job-top-info h5 { font-size: 18px; }
}
@media only screen and (max-width: 991px) {
    .filter-icon {
        width: 30px;
    }
    .filter-top-box {
        padding-right: 35px;
    }
}

.my-custom-confirm-modal .modal-content {
    border-radius: 0; /* No rounded corners */
    border: 2px solid #f6851f; /* No border */
}
.btn {
    font-weight: 500;
    font-size: 0.729vw;
    border-radius: 0.5rem;
}
.btn.btn-primary {
    border: 1px solid #fad33a;
    background: #f6851f;
    color: #fff;
}
.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-primary:focus {
    color: #fff;
    background: #cf6a12;
    border-color: #cf6a12;
}
.btn.btn-outline-primary {
    border: 1px solid #f6851f;
    background: #fff;
    color: #f6851f;
}
.btn.btn-outline-primary:hover,
.btn.btn-outline-primary:active,
.btn.btn-outline-primary:focus {
    color: #fff;
    background: #f6851f;
}
.btn.btn-outline-primary:disabled {
    border-color: #b4b4b4;
    color: #b4b4b4;
}

.modal-header,
.modal-footer {
    border: 0;
}
.modal-header h4 {
    font-weight: 700;
    font-size: 20px;
    color: #051920;
    border: 0;
}
.job-info-scroll {
    font-size: 14px;
    color: #103558;
    max-height: 67vh;
    overflow-y: auto;
    overflow-x: hidden;
}
.job-info-scroll h5 {
    font-weight: 700;
    font-size: 14px;
    color: #000000;
    margin-bottom: 0.2rem;
}
.job-info-scroll p {
    margin-bottom: 0.5rem;
}
.job-info-scroll p:last-child {
    margin-bottom: 0;
}

.job-profile-img-box {
    background: #103558;
    min-width: 6.729vw;
    width: 6.729vw;
}
.job-profile-img-box:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 6.729vw;
    bottom: 0;
    background: #103558;
    z-index: -1;
}
.job-profile-card-list .card {
    z-index: 1;
    border: 0;
}
.job-profile-img-box figure {
    position: relative;
    overflow: hidden;
    padding-top: 100%;
    border-radius: 50%;
    margin: 0;
}
.job-profile-img-box figure img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.job-profile-img-box .btn {
    font-size: 0.7291666667vw;
}
.job-profile-card-list .job-top-info {
    color: #027ea8;
}
.job-profile-card-list .label-list .badge:first-child {
    min-width: inherit;
}

.job-profile-card-list .available-job-list {
    font-size: 14px;
}
.job-profile-card-list .available-job-list li span {
    height: 18px;
    width: 18px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
}
.job-profile-card-list .available-job-list li {
    position: relative;
    padding-left: 30px;
}
.page-footer {
    padding: 1rem;
    background: #fefcf3;
    position: sticky;
    bottom: 0;
    margin-left: -1.8rem;
    margin-right: -1.8rem;
    margin-bottom: -1.9rem;
    z-index: 5;
    margin-top: 1.5rem;
}
.job-nav .new_nav .nav-tabs {
    background: transparent;
    border-bottom: 1px solid #103558;
    margin-bottom: 1rem;
}
.job-nav .new_nav .nav-tabs .nav-item .nav-link {
    background: transparent;
    padding: 0.4rem 2.3rem;
}
.job-profile-card-list .available-job-list {
    font-size: 14px;
}
.job-profile-card-list .available-job-list li span {
    height: 18px;
    width: 18px;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
}
.job-profile-card-list .available-job-list li {
    position: relative;
    padding-left: 30px;
}
.page-footer {
    padding: 1rem;
    background: #fefcf3;
    position: sticky;
    bottom: 0;
    margin-left: -1.8rem;
    margin-right: -1.8rem;
    margin-bottom: -1.9rem;
    z-index: 5;
    margin-top: 1.5rem;
}
.job-nav .new_nav .nav-tabs {
    background: transparent;
    border-bottom: 1px solid #103558;
    margin-bottom: 1rem;
}
.job-nav .new_nav .nav-tabs .nav-item .nav-link {
    background: transparent;
    padding: 0.4rem 2.3rem;
}
.filter-search {
    position: sticky;
    top: 0;
    z-index: 5;
    background: #e6e7e9 !important;
}
.job-profile-card-list {
    max-height: 60vh;
    overflow: auto;
}
.alert-job-profile-box {
    border-radius: 8px;
    background: #fff;
    padding: 0.7rem;
    display: flex;
    align-items: center;
}
.alert-job-profile-img {
    width: 70px;
    margin-right: 0.7rem;
    min-width: 70px;
}
.alert-job-profile-img figure {
    margin: 0;
    position: relative;
    overflow: hidden;
    padding-top: 100%;
    border-radius: 100%;
}
.alert-job-profile-img figure img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
}
.alert-job-profile-info {
    font-size: 14px;
    color: #103558;
    line-height: 1.3;
    font-weight: 400;
}
.alert-job-profile-info h5 {
    font-size: 1.2rem;
    margin-bottom: 0.1rem;
    color: #0c2d40;
    font-weight: 700;
}
.alert-job-profile-time {
    font-size: 13px;
    color: #a80202;
}
.alert-job-profile-label {
    display: inline-block;
    color: #3d0e0e;
    font-size: 11px;
    background: #fff2f2;
    border-radius: 3px;
    padding: 0.1rem 0.5rem;
    border: 1px solid #ff5045;
    margin-bottom: 0.2rem;
}
.alert-job-profile-info p {
    margin-bottom: 0.2rem;
}
.document-list-box {
    display: flex;
    background: #e3fcff;
    border-radius: 8px;
    margin-bottom: 1rem;
    padding: 0.6rem;
    align-items: center;
}
.document-list-box figure {
    margin: 0 0.6rem 0 0;
    width: 43px;
    min-width: 43px;
}
.document-list-box figure svg {
    width: 100%;
    height: auto;
}
.document-info h5,
.document-info p {
    margin-bottom: 0.3rem;
    color: #000;
}
.document-info h5:last-child,
.document-info p:last-child {
    margin-bottom: 0;
}
.document-info p {
    word-break: break-all;
    line-height: 1.1;
}
.job-profile-card-list .available-job-list {
    font-size: 0.7291666666666666vw;
}
.job-profile-card-list .available-job-list li span {
    height: 0.9375vw;
    width: 0.9375vw;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
}
.job-profile-card-list .available-job-list li {
    position: relative;
    padding-left: 1.5625vw;
}

.job-nav .new_nav .nav-tabs {
    background: transparent;
    border-bottom: 1px solid #103558;
    margin-bottom: 1rem;
}
.job-nav .new_nav .nav-tabs .nav-item .nav-link {
    background: transparent;
    padding: 0.4rem 2.3rem;
}
.filter-search {
    position: sticky;
    top: 0;
    z-index: 5;
    background: #e6e7e9;
}
.job-profile-card-list {
    max-height: 60vh;
    overflow: auto;
}
.alert-job-profile-box {
    border-radius: 8px;
    background: #fff;
    padding: 0.7rem;
    display: flex;
    align-items: center;
}
.alert-job-profile-img {
    width: 70px;
    margin-right: 0.7rem;
    min-width: 70px;
}
.alert-job-profile-img figure {
    margin: 0;
    position: relative;
    overflow: hidden;
    padding-top: 100%;
    border-radius: 100%;
}
.alert-job-profile-img figure img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
}
.alert-job-profile-info {
    font-size: 14px;
    color: #103558;
    line-height: 1.3;
    font-weight: 400;
}
.alert-job-profile-info h5 {
    font-size: 1.2rem;
    margin-bottom: 0.1rem;
    color: #0c2d40;
    font-weight: 700;
}
.alert-job-profile-time {
    font-size: 13px;
    color: #a80202;
}
.alert-job-profile-label {
    display: inline-block;
    color: #3d0e0e;
    font-size: 11px;
    background: #fff2f2;
    border-radius: 3px;
    padding: 0.1rem 0.5rem;
    border: 1px solid #ff5045;
    margin-bottom: 0.2rem;
}
.alert-job-profile-info p {
    margin-bottom: 0.2rem;
}

.document-list-box {
    display: flex;
    background: #88c9e3;
    border-radius: 8px;
    margin-bottom: 1rem;
    padding: 0.6rem;
    align-items: center;
}
.document-list-box figure {
    margin: 0 0.6rem 0 0;
    width: 43px;
    min-width: 43px;
}
.document-list-box figure svg {
    width: 100%;
    height: auto;
}
.document-info h5,
.document-info p {
    margin-bottom: 0.3rem;
    color: #000;
}
.document-info h5:last-child,
.document-info p:last-child {
    margin-bottom: 0;
}
.document-info p {
    word-break: break-all;
    line-height: 1.1;
}

.profile-model-box {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
}
.profile-model-img {
    width: 100px;
    min-width: 100px;
    margin: 0 1rem 0 0;
}
.profile-model-img figure {
    margin: 0 0 0 0;
    position: relative;
    overflow: hidden;
    padding-top: 100%;
    border-radius: 50%;
}
.profile-model-img figure img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
}
.profile-modal-content.fixwidth {
    min-width: 600px;
}
.profile-model-info h4 {
    font-size: 24px;
}
.profile-model-info .label-list .badge:first-child {
    min-width: inherit;
}
.profile-model-info .label-list .badge {
    font-size: 20px;
}

.modal-body .nav-tabs {
    background: transparent;
    border-bottom: 1px solid #027ea8;
    margin-bottom: 1rem;
}
.modal-body .nav.nav-tabs .nav-item .nav-link {
    border: 0;
    border-radius: 0;
    font-weight: 600;
    color: #027ea8;
    font-size: 16px;
    background: transparent;
    text-align: center;
}
.modal-body .nav.nav-tabs .nav-item .nav-link.active {
    color: #fff;
    background: #027ea8;
}
.profile-model-nav .nav.nav-tabs .nav-item {
    width: 33.333333%;
}
.profile-model-nav .nav.nav-tabs {
    padding: 0 1rem;
    margin: 0 -1rem 1rem;
}

.profile-summery-box {
    font-size: 15px;
    color: #103558;
}
.profile-summery-box ul {
    margin: 0 0 0.5rem 0;
}
.profile-summery-box ul ul {
    list-style: disc;
}
.profile-modal-content .modal-body {
    border-bottom: 1px solid #027ea8;
}

.profile-personal-information {
    color: #103558;
    font-size: 14px;
}
.profile-personal-information h4 {
    font-size: 16px;
    color: #103558;
}
.profile-personal-information h5 {
    font-size: 14px;
    color: #103558;
}
.profile-personal-information hr {
    border-color: #c9c9c9;
    opacity: 1;
}
.verified-round {
    height: 10px;
    width: 10px;
    display: block;
    background: #69c68e;
    border-radius: 100%;
    margin: 0.3rem 0.4rem 0 0;
}
.person-file-box {
    display: flex;
    align-items: center;
    background: #88c9e3;
    padding: 0.6rem;
    border-radius: 8px;
    color: #7d7787;
    font-size: 12px;
}
.person-file-box figure {
    margin: 0 0.3rem 0 0;
    width: 45px;
}
.person-file-box figure svg {
    width: 100%;
    height: auto;
}
.person-file-info h6 {
    font-size: 13px;
    color: #0e0320;
    font-weight: 400;
    margin: 0 0 0.1rem 0;
}
.person-resume-box .person-file-box {
    position: relative;
    max-width: 335px;
    padding-right: 2.5rem;
}
.add-file {
    position: absolute;
    top: 0;
    bottom: 0;
    height: 22px;
    width: 22px;
    right: 0.7rem;
    margin: auto;
}
.add-file svg {
    width: 100%;
    height: auto;
}

.chat-log-bottom {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding-top: 1rem;
}
.chat-log-top {
    flex-grow: 1;
    overflow-y: auto;
}
.chat-log {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.profile-model-nav .tab-pane {
    height: 100%;
}
.profile-model-nav {
    height: calc(100% - 154px);
}
.profile-model-nav .tab-content {
    height: calc(100% - 55px);
}
.chat-log-bottom .send-btn {
    background: transparent;
    border: 0;
    padding: 0;
    height: 36px;
    width: 36px;
    margin-top: 0.5rem;
    outline: none;
}
.chat-log-bottom .form-control {
    border-color: #027ea8;
}
.main .sidebar.sidebar-tab {
    padding: 20px 0 20px;
    padding: 0 0 20px 0;
    top: 6.3vw;
    top: 5.8vw;
}
.sidebar-tab .slidbarBtn {
    top: 60px;
}
.sidebar-tab .sidebar-tabs button {
    font-weight: 800;
    line-height: 1;
}
.sidebar-tab .scroll-event {
    margin: 1rem 0 0 1rem;
}
.alert-job-profile-list {
    margin: -1rem 1rem 0 0;
}
.sidebar-tab .ng-scroll-viewport-wrapper {
    bottom: 1rem;
}
.msger-timeline {
    display: block;
    text-align: center;
    color: #000000;
    font-size: 14px;
    margin-bottom: 1.5rem;
    font-weight: 700;
}
.msger-img figure {
    width: 35px;
    height: 35px;
    border-radius: 100px;
    border: 1px solid #e1e1e1;
    background: #fff;
    color: #027ea8;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0.5rem 0 0;
    position: relative;
    overflow: hidden;
}
.msger-img figure span {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.msger-img figure img {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}
.msger-msg {
    border-radius: 8px;
    max-width: 70%;
    width: auto;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 7px 10px 15px rgba(0, 0, 30, 0.1);
    padding: 0.7rem 1rem;
    font-size: 14px;
    color: #103558;
}
.msger-msg p {
    margin: 0;
}
.msger-msg + .msger-msg {
    margin-top: 0.5rem;
}
.msg-time {
    display: block;
    margin-top: 0.5rem;
    color: #027ea8;
    font-size: 12px;
    font-weight: 700;
}
.msger-chat-box {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
}
.msger-msg-box {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.msger-chat-box.msger-chat-right {
    flex-direction: row-reverse;
}
.msger-chat-right .msger-msg {
    margin-left: auto;
    background: #027ea8;
    color: #fff;
    box-shadow: -10px 10px 15px rgba(2, 126, 168, 0.2);
}
.msger-chat-right .msger-img figure {
    margin-right: 0;
    margin-left: 0.5rem;
}
.msger-chat-right .msg-time {
    text-align: right;
}

.msger-chat-left .msger-msg {
    margin-right: auto;
}
.p-calendar {
    height: 38px;
}
.p-timepicker {
    background-color: aliceblue;
}

/* This CSS increases specificity by targeting a custom class on the ng-select element.
   You will add 'custom-select-dropdown' class to your ng-select component. */

.custom-select-dropdown .ng-select-container .ng-value-container .ng-value {
    display: inline-block;
    height: auto;
    max-height: 200px; /* Adjust this value as needed */
    overflow-y: auto;
}

/* Ensures that the dropdown items do not exceed a certain height */
.custom-select-dropdown .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 200px; /* Adjust this value as needed */
    overflow-y: auto;
}

/* Optional: Adjusts the height of the individual items if needed */
.custom-select-dropdown .ng-option {
    height: auto;
    padding: 4px 10px; /* Adjust padding as needed */
}

//
// :host ::ng-deep .ng-select .ng-value-container {
//     display: flex;
//     flex-wrap: wrap;
//     max-height: 100px;
//     overflow-y: auto;
// }

// :host ::ng-deep .ng-select .ng-value-container .ng-value {
//     flex-shrink: 0;
// }
// /* Targets the container of the selected items */
// .ng-select .ng-value-container {
//     display: flex; /* Stack children inline */
//     flex-wrap: wrap; /* Wrap them to the next line */
//     max-height: 100px; /* Set a max-height for the container */
//     overflow-y: auto; /* Enable vertical scrolling */
// }

// /* Correct any additional padding/margin issues that may arise */
// .ng-select .ng-value-container .ng-value {
//     flex-shrink: 0; /* Prevent flex items from shrinking */
// }

.ql-container .ql-snow {
    height: 220px;
}

.interview-date-label {
    display: inline-block;
    font-size: 14px;
    color: #103558;
    background: #c9c9c9;
    padding: 0.6rem 1rem;
    line-height: 1.25;
}
.interview-list-box .job-profile-card-list {
    max-height: 73vh;
    background: #c9c9c9;
    padding: 0 1rem 1rem 1rem;
}

.tab-notification {
    background: #ff5045;
    color: #fff;
    min-height: 1.3541666666666667vw;
    min-width: 1.3541666666666667vw;
    font-size: 0.7291666666666666vw;
    font-weight: 700;
    height: auto;
    width: auto;
    padding: 2px 5px;
}
.page-footer-fix {
    padding-bottom: 86px;
}
.page-footer-fix .page-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}

//calendar

.mbsc-calendar-button.mbsc-button {
    color: $primary !important;
    font-weight: bold !important;
}
table.dataTable > tbody > tr > td .btn.btn-icon {
    height: auto;
    width: auto;
    padding: 0.3rem;
}
div.custom-calendar .mbsc-eventcalendar {
    background: #e6e7e8;
}
.custom-calendar .mbsc-eventcalendar div.mbsc-calendar-wrapper {
    border: 0;
}
.custom-calendar .mbsc-eventcalendar .mbsc-calendar-body-inner {
    margin: 0;
    border: 0;
}
.custom-calendar .mbsc-eventcalendar .mbsc-calendar-wrapper .mbsc-calendar-header {
    background: transparent;
    margin-bottom: 0;
    border-bottom: 0;
}
.custom-calendar .mbsc-eventcalendar .mbsc-calendar-wrapper .mbsc-calendar-controls {
    margin-bottom: 1.2rem;
}

.custom-calendar
    .mbsc-eventcalendar
    .mbsc-calendar-wrapper
    .mbsc-calendar-header
    .mbsc-textfield-wrapper.mbsc-form-control-wrapper {
    width: 100%;
    margin: 0 auto;
}
.custom-calendar .mbsc-eventcalendar .mbsc-calendar-wrapper .mbsc-calendar-header .mbsc-textfield {
    border: 1px solid #103558;
    height: 1.9791666666666667vw;
    padding: 0.5rem 2.775em 0.5rem 0.5rem;
}
.custom-calendar .mbsc-eventcalendar .mbsc-calendar-wrapper .mbsc-calendar-header .mbsc-textfield-icon {
    left: auto;
    right: 0.3rem;
    top: 0.1rem;
    font-size: 1.1979166666666667vw;
    color: #103558;
}
.custom-calendar .mbsc-eventcalendar .mbsc-calendar-wrapper .mbsc-calendar-header .mbsc-button .mbsc-calendar-title {
    color: #051920;
    font-size: 1.0416666666666667vw;
    font-weight: 400;
}
.custom-calendar
    .mbsc-eventcalendar
    .mbsc-calendar-wrapper
    .mbsc-calendar-header
    mbsc-calendar-prev
    .mbsc-calendar-button,
.custom-calendar
    .mbsc-eventcalendar
    .mbsc-calendar-wrapper
    .mbsc-calendar-header
    mbsc-calendar-next
    .mbsc-calendar-button {
    padding: 0;
    height: 21px;
    width: 20px;
    position: relative;
}
.custom-calendar
    .mbsc-eventcalendar
    .mbsc-calendar-wrapper
    .mbsc-calendar-header
    mbsc-calendar-prev
    .mbsc-calendar-button
    .mbsc-button-icon,
.custom-calendar
    .mbsc-eventcalendar
    .mbsc-calendar-wrapper
    .mbsc-calendar-header
    mbsc-calendar-next
    .mbsc-calendar-button
    .mbsc-button-icon {
    display: none;
}
.custom-calendar
    .mbsc-eventcalendar
    .mbsc-calendar-wrapper
    .mbsc-calendar-header
    mbsc-calendar-next
    .mbsc-calendar-button:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    width: 0;
    margin: auto;
    border-left: 7px solid #051920;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
}
.custom-calendar
    .mbsc-eventcalendar
    .mbsc-calendar-wrapper
    .mbsc-calendar-header
    mbsc-calendar-prev
    .mbsc-calendar-button:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0;
    width: 0;
    margin: auto;
    border-right: 7px solid #051920;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
}
.custom-calendar .mbsc-eventcalendar .mbsc-calendar-wrapper .mbsc-calendar-header .mbsc-ios.mbsc-segmented {
    background: transparent;
}
.custom-calendar .mbsc-eventcalendar .mbsc-calendar-wrapper .mbsc-calendar-header .mbsc-segmented-selectbox-inner {
    background: #f6851f;
}
.custom-calendar
    .mbsc-eventcalendar
    .mbsc-calendar-wrapper
    .mbsc-calendar-header
    .mbsc-segmented-item-selected
    .mbsc-button-icon {
    color: #fff;
}
.custom-calendar .mbsc-eventcalendar .mbsc-calendar-wrapper .mbsc-calendar-week-days .mbsc-calendar-week-day {
    font-size: 1.0416666666666667vw;
    color: #fff;
    background: #027ea8;
    font-weight: 400;
    border-color: #fff !important;
    padding: 0.5rem;
    height: auto;
    line-height: 1.25;
    text-align: center !important;
}
.custom-calendar
    .mbsc-eventcalendar
    .mbsc-calendar-wrapper
    .mbsc-calendar-week-days
    .mbsc-calendar-week-day:first-child {
    border-left: 0;
}
.custom-calendar
    .mbsc-eventcalendar
    .mbsc-calendar-wrapper
    .mbsc-calendar-week-days
    .mbsc-calendar-week-day:last-child {
    border-right: 0;
}
.custom-calendar .mbsc-eventcalendar .mbsc-schedule-header-item {
    font-size: 1.0416666666666667vw;
    color: #fff;
    background: #027ea8;
    font-weight: 400;
    border-color: #fff !important;
    padding: 0.5rem;
    height: auto;
    line-height: 1.25;
    text-align: center !important;
}
.custom-calendar .mbsc-eventcalendar .mbsc-schedule-header-item .mbsc-schedule-header-dayname-curr {
    color: #fff;
}
.custom-calendar .mbsc-eventcalendar .mbsc-schedule-header-item .mbsc-schedule-header-day-today {
    background: #ffd4ac;
    border-color: #ffd4ac;
    color: #103558;
}
.custom-calendar .mbsc-eventcalendar .mbsc-schedule-header-item .mbsc-schedule-header-day {
    height: 1.575em;
    line-height: 1.675em;
    width: 1.575em;
    font-size: 0.9rem;
    font-weight: 600;
    position: relative;
    top: -2px;
}

// .custom-calendar .mbsc-eventcalendar .mbsc-schedule-grid-wrapper { overflow-x: hidden; overflow-y: auto; max-height: 68vh; }
.custom-calendar .mbsc-eventcalendar .mbsc-schedule-wrapper {
    max-height: 76.4vh;
}

.custom-calendar .mbsc-eventcalendar .mbsc-calendar-wrapper .mbsc-calendar-day.mbsc-disabled {
    background: #e6e7e8;
    border-color: #e6e7e8;
    color: #027ea8;
    text-align: left !important;
}
.custom-calendar .mbsc-eventcalendar .mbsc-calendar-wrapper .mbsc-calendar-day.mbsc-disabled:after {
    border-color: #e6e7e8;
}

.custom-calendar .mbsc-eventcalendar .mbsc-calendar-wrapper .mbsc-calendar-day-text {
    opacity: 1;
    border-radius: 0;
    margin-top: 0;
    margin-left: 2px;
}
.custom-calendar .mbsc-eventcalendar .mbsc-calendar-wrapper .mbsc-selected .mbsc-calendar-cell-text {
    background: #ffd4ac;
    border-color: #ffd4ac;
    color: #103558;
}
.custom-calendar .mbsc-eventcalendar .mbsc-calendar-wrapper .mbsc-hover .mbsc-calendar-cell-text {
    background: #ffd4ac;
    border-color: #ffd4ac;
    color: #103558;
}
.custom-calendar .mbsc-eventcalendar .mbsc-calendar-wrapper .mbsc-calendar-body {
    border: 1px solid #027ea8;
}

.date-input .mat-mdc-form-field-subscript-wrapper {
    display: none;
}
.date-input .mat-mdc-text-field-wrapper {
    background: #fff !important;
    border: 1px solid #103558;
    border-radius: 6px;
    color: #103558;
    box-shadow: none;
    height: 38px;
    position: relative;
}
.date-input .mat-mdc-form-field {
    width: 100%;
}
.date-input .mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
    width: 100%;
    padding: 0;
    height: 38px;
    min-height: 38px;
    margin: 0;
}
.date-input .mat-mdc-icon-button.mat-mdc-button-base {
    padding: 0;
    height: 38px;
    width: 38px;
    color: #7987a1;
}
.date-input .mat-date-range-input {
    margin-top: 6px;
}
.date-input .mat-date-range-input-container {
    font-family: 'Red Hat Display', sans-serif;
    font-size: 0.9rem;
}
.date-input .mdc-line-ripple {
    display: none;
}

@media only screen and (max-width: 1400px) {
    .date-input .mat-mdc-text-field-wrapper {
        height: 35px;
    }
    .date-input .mat-mdc-form-field-type-mat-date-range-input .mat-mdc-form-field-infix {
        height: 35px;
        min-height: 35px;
    }
    .date-input .mat-mdc-icon-button.mat-mdc-button-base {
        height: 35px;
    }
    .date-input .mat-date-range-input {
        margin-top: 2px;
    }
    .btn.apply-btn {
        height: 35px;
        padding-top: 0;
        padding-bottom: 0;
    }
}
.custom-calendar
    .mbsc-eventcalendar
    .mbsc-calendar-wrapper
    .mbsc-calendar-cell.mbsc-calendar-day.mbsc-calendar-day-labels {
    text-align: left;
}
.custom-calendar .mbsc-eventcalendar .mbsc-calendar-wrapper .mbsc-calendar-day:first-child {
    border-left: 0;
}
.custom-calendar .mbsc-eventcalendar .mbsc-calendar-wrapper .mbsc-calendar-day:last-child {
    border-right: 0;
}

.custom-calendar .mbsc-eventcalendar .mbsc-calendar-wrapper .mbsc-calendar-month-name {
    display: none;
}

.custom-calendar .mbsc-page {
    height: 83vh;
}
// .custom-calendar .mbsc-eventcalendar .mbsc-schedule-event { position: relative; min-width: 100%; }
// .custom-calendar .mbsc-eventcalendar .mbsc-schedule-event-inner { position: relative; }

.dropdown-no-form-label .form-label {
    display: none;
}
.UseShiftSetting {
    max-width: 700px;
    width: 100%;
    font-size: 0.7291666666666666vw;
    text-align: center;
    padding: 0 2rem;
}
.shift-top {
    padding: 0 0.8333333333333334vw;
    width: 33.333333%;
    margin-bottom: 0.8333333333333334vw;
    font-size: 0.8333333333333334vw;
}
.shift-top p {
    max-width: 7.8125vw;
    margin: 0 auto;
    width: 100%;
}
.shift-bottom {
    padding: 0 0.8333333333333334vw;
    width: 33.333333%;
}
.shift-bottom p {
    max-width: 9.895833333333334vw;
    margin: 0 auto;
    width: 100%;
}
.shift-start p {
    max-width: 4.0625vw;
    margin: 0 auto;
    width: 100%;
}
.no-form-label label {
    display: none;
}
.shift-bottom-box > div {
    position: relative;
    padding-top: 1.3020833333333333vw;
}
.shift-bottom-box > div:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    width: 0;
    margin: auto;
    border-bottom: 0.9375vw solid #3adcff;
    border-left: 0.8333333333333334vw solid transparent;
    border-right: 0.8333333333333334vw solid transparent;
}
.shift-bottom-box {
    position: relative;
}
.shift-bottom-box .shift-start {
    position: absolute;
    left: 0;
    transform: translateX(-50%);
    top: 0;
}
.shift-bottom-box .shift-end {
    position: absolute;
    right: 0;
    transform: translateX(50%);
    top: 0;
}
.shift-bottom-box .shift-end:before {
    border-bottom-color: #69c68e;
}
.shift-top-box {
    border-bottom: 0.3645833333333333vw solid #027ea8;
}
.DoNotUseShiftSetting {
    max-width: 29.895833333333332vw;
    width: 100%;
    font-size: 0.8333333333333334vw;
}
.input-width-50 .form-control {
    width: 50px;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    text-align: center;
}
.shift-bottom-box .fv-plugins-message-container .input-error {
    text-align: center;
    margin-top: 0.5rem;
}
@media only screen and (max-width: 1500px) {
    .main .sidebar.sidebar-tab {
        top: 6.6vw;
    }
}
@media only screen and (max-width: 1400px) {
    .main .sidebar.sidebar-tab {
        top: 6.8vw;
    }
}
@media only screen and (max-width: 1300px) {
    .main .sidebar.sidebar-tab {
        top: 6.8vw;
    }
}
@media only screen and (max-width: 1199px) {
    .main .sidebar.sidebar-tab {
        top: 7.6vw;
    }
}
@media only screen and (max-width: 899px) {
    .main .sidebar.sidebar-tab {
        top: 8.4vw;
    }
}

@media only screen and (min-width: 992px) {
    .chat-main-model .profile-model-nav,
    .modal-content.fixwidth.chat-main-model {
        min-width: 700px;
    }
}

.chat-main-model .new_nav .nav.nav-tabs .nav-item .nav-link {
    padding: 0.7rem 1rem;
}
.chat-main-model .profile-model-nav .nav.nav-tabs .nav-item {
    width: auto;
}
.chat-main-model .profile-model-nav .nav.nav-tabs {
    margin: 0;
    padding: 0;
}
.chat-main-model .job-top-box figure {
    height: 5.573vw;
    min-width: 5.573vw;
}
.chat-main-model .job-top-info figure {
    height: 2.344vw;
    min-width: 2.344vw;
    max-width: 2.344vw;
}
.chat-main-model .job-top-info figure img {
    height: 100%;
    width: 100% !important;
    object-fit: cover;
    object-position: center center;
    max-height: 100%;
}
.chat-main-model .all-job-box {
    border: 0 !important; /*border-bottom: 1px solid #027EA8 !important;*/
    margin: 0 !important;
}
.chat-main-model .new_nav {
    border: 0;
    border-bottom: 1px solid #027ea8;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 2;
    margin: 0 0 -1px 0;
}
.chat-main-model .job-top-box {
    margin: 0;
    padding: 0;
}
.chat-main-model .modal-footer {
    position: sticky;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    border-top: 1px solid #027ea8;
}
.chat-main-model .job-profile-card-list {
    min-height: 100%;
    height: 100%;
    padding-bottom: 0;
    overflow: inherit;
}
.chat-main-model .profile-model-nav .tab-content {
    height: calc(89vh - 58px);
}

.slide-chat-log-bottom {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e6e7e8;
    border-bottom: 1px solid #e6e7e8;
    position: sticky;
    bottom: 0;
    background: #e6e7e8;
    z-index: 3;
}
.chat-main-model .document-list {
    border-top: 1px solid #027ea8;
}
.chat-main-model .document-list.chat-log-list {
    border-bottom: 1px solid #027ea8;
}
.chat-main-model .chat-log.job-profile-card-list {
    padding: 1rem;
    padding-bottom: 275px;
    height: 100%;
    overflow: auto;
}
.slide-chat-log-bottom .send-btn {
    min-width: 90px;
    margin-left: 1rem;
    font-weight: 600;
    letter-spacing: 0.02rem;
}
.slide-chat-log-bottom .form-control {
    width: 100%;
    border-color: #c9c9c9;
    font-size: 14px;
}

.modal-content.fixwidth.chat-main-model {
    min-height: 100vh;
    overflow: hidden;
}
.chat-main-model .chat-log {
    max-height: calc(100vh - 275px);
    padding-bottom: 0;
}
.cat-conversation-top .modal-header {
    position: sticky;
    top: 0;
    z-index: 3;
}
.cat-conversation-top .chat-log-list {
    position: sticky;
    top: 62px;
    background: #fff;
    z-index: 3;
}

.chat-main-model .profile-model-nav {
    overflow: hidden;
    height: 100%;
}
.chat-main-model .profile-model-nav .tab-content {
    overflow: auto;
    height: calc(100vh - 180px);
}
.text-purple {
    color: #e0a6e0;
}
// .mbsc-eventcalendar .event-title {
//     display: block;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     font-size: 12px !important;
//     line-height: 1.4 !important;
//     padding: 4px 6px !important;
//     color: #333 !important;
//     background-color: rgba(255, 255, 255, 0.8);
//     border-radius: 4px;
//     box-sizing: border-box;
//     margin: 2px;
// }

// .mbsc-eventcalendar .mbsc-calendar-cell {
//     height: auto;
//     padding: 4px; /* Adds padding inside the calendar cells */
// }
.mbsc-eventcalendar .event-title {
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: flex-start; /* Align text to the left */
    // padding: 2px 4px; /* Reduce padding to maximize space */
    // font-size: 10px; /* Reduce font size for space and readability */
    // line-height: 1.2; /* Reduce line height to compact the text */
    // white-space: nowrap; /* Prevent text wrapping */
    // overflow: hidden; /* Hide overflow text */
    // text-overflow: ellipsis; /* Use ellipsis for overflow text */
    // background: rgba(255, 255, 255, 0.8); /* Slightly transparent background */
    // border-radius: 4px; /* Rounded corners for aesthetics */

    padding: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background: #f8ed92;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    width: 120px;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.mbsc-eventcalendar .event-title div {
    width: 100%; /* Ensure divs fill the container */
    overflow: hidden; /* Avoid overflow problems */
    text-overflow: ellipsis; /* Add ellipsis to overflowing text */
}
.mbsc-calendar-cell {
    // height: 100px !important; /* Increase height to accommodate more content */
    // min-width: 120px; /* Adjust the minimum width as needed */
    padding: 2px; /* Reduce padding to increase text space */
}
.mbsc-eventcalendar .mbsc-calendar-table {
    width: 100%; /* Ensure the table uses the full width of its container */
}

.mbsc-eventcalendar .mbsc-event {
    white-space: normal; /* Allows for natural text wrapping */
}
.shift-position-card-list {
    max-height: 36vh;
    overflow: auto;
}
.shift-position-card-list .card {
    z-index: 1;
    border: 0;
}

.external-drop-cont-row .mbsc-calendar-label-text {
    width: 100%;
}
.external-drop-cont-row .mbsc-eventcalendar .event-title {
    padding: 0.6172839506172839vh 1.02880658436214vh;
    width: 100%;
    font-size: 1.131687242798354vh;
}
.external-drop-cont-row .mbsc-eventcalendar .event-title div {
    margin: 0.205761316872428vh 0;
    margin: 0 0;
}
.external-drop-cont-row .mbsc-calendar-button.mbsc-button .mbsc-calendar-title {
    font-weight: normal;
    font-size: 1.0416666666666667vw;
    color: #027ea8;
    font-family: 'Red Hat Display';
}
.external-drop-cont-row .mbsc-ios.mbsc-calendar-cell {
    border-color: #e4e5e6;
    color: #103558;
    background-color: #ffffff;
    font-family: 'Red Hat Display';
    font-weight: normal;
    min-width: 120px; /* Adjust based on your layout needs */
    height: 110px !important;
    width: 220px !important; /* Adjust height to fit content */
}
.external-drop-cont-row .mbsc-ios.mbsc-calendar-cell.mbsc-calendar-day-outer {
    color: #027ea8;
    background-color: #f0f0f0;
}
.external-drop-cont-row .mbsc-ios.mbsc-calendar-cell.mbsc-selected {
    background: #d3e5ec;
}
.external-drop-cont-row .mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
    background-color: #027ea8;
    color: #fff;
    border-color: #027ea8;
}
.external-drop-cont-row .mbsc-ios.mbsc-calendar-cell .mbsc-calendar-day-text,
.external-drop-cont-row .mbsc-ios.mbsc-calendar-cell .mbsc-calendar-month-name {
    font-family: 'Red Hat Display';
    font-weight: normal;
    opacity: 1;
    font-size: 0.6770833333333334vw;
}
.external-drop-cont-row .mbsc-ios.mbsc-calendar-day:after {
    border-color: #e4e5e6;
}
.external-drop-cont-row .mbsc-ios.mbsc-calendar-width-md .mbsc-calendar-week-day {
    color: #103558;
    font-family: 'Red Hat Display';
    font-weight: 600;
    font-size: 1.0416666666666667vw;
}
.external-drop-cont-row .mbsc-calendar-button.mbsc-button.mbsc-calendar-button-today {
    font-family: 'Red Hat Display';
    font-weight: normal !important;
    font-size: 15px;
    color: #027ea8 !important;
}
.external-drop-cont-row .mbsc-ios.mbsc-calendar-label-start.mbsc-ltr .mbsc-calendar-label-background {
    display: none;
}
.external-drop-cont-row .mbsc-ios.mbsc-calendar-cell .mbsc-calendar-day-text {
    height: 0.9375vw;
    width: 0.9375vw;
    line-height: 0.9375vw;
}
.external-drop-cont-row .mbsc-calendar-label-wrapper {
    width: 100%;
}
.price-per-hour-control .form-control {
    width: 100px;
    margin: 0.25rem 0;
}
.date-picker-control .form-control {
    border-right: 0;
}
.date-picker-control .input-group-text {
    background: $white;
    border-color: #103558;
}
.date-picker-control .input-group-text i {
    color: #103558 !important;
}
.shift-details-modal .modal-body .status-badge {
    position: relative;
    display: inline-block;
    width: 100%;
}
.shift-details-modal .status-badge i {
    position: absolute;
    left: 8px;
}
.shift-details-modal .status-badge span {
    padding-left: 20px;
    display: block;
    white-space: normal;
    text-align: left;
}
.label-normal label {
    font-weight: normal;
    font-size: 16px;
    color: #103558;
}
.reason-dropdown label {
    font-size: 16px;
    color: #000000;
}

.ShiftSettings {
    max-width: 35.895833vw;
    width: 100%;
    font-size: 0.8333333333333334vw;
}
.msger-badge {
    border-radius: 50%;
    color: #fff;
    width: 35px;
    height: 35px;
}

/*
Added on 30 May 2024 By Nilesh Socha Data table ui update */
.dt-search {
    position: relative;
    max-width: 366px;
    width: 100%;
}
.dt-search::before {
    content: '\e9cc';
    font-family: 'feather';
    position: absolute;
    left: 0.8rem;
    top: 0.6rem;
    line-height: 1;
    color: #103558;
}
div.dt-container .dt-search .dt-input {
    border: 1px solid #103558;
    border-radius: 6px;
    color: #103558;
    background-color: #fff;
    padding: 0.469rem 0.8rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    display: block;
    width: 100%;
    padding-left: 2.5rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin: 0;
}
div.dt-container .dt-search .dt-input:focus {
    color: #000;
    background-color: #fff;
    border-color: #ced4da;
    outline: 0;
    box-shadow: none;
}
div.dt-container .dt-search label:empty {
    display: none;
}
.dt-container .dt-layout-row:first-child:not(.dt-layout-table) {
    display: flex;
    flex-direction: row-reverse;
}
.dt-container .dt-layout-row:first-child:not(.dt-layout-table) .dt-layout-cell {
    width: 50%;
}

.dt-length {
    display: flex;
    align-items: center;
    justify-content: end;
    font-size: 0.9rem;
}
.dt-length label {
    margin-left: 0.4rem;
}
div.dt-container .dt-length .dt-input {
    // border: 0;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #b9b9bc;
    text-align: center;
    font-weight: 500;
}
.dt-info {
    font-size: 0.9rem;
}
div.dt-container .dt-paging .dt-paging-button,
div.dt-container .dt-paging .dt-paging-button.disabled,
div.dt-container .dt-paging .dt-paging-button.current {
    height: 2rem;
    width: 2rem;
    padding: 0;
    background: #fff;
    color: #0c1427;
    font-size: 0.8rem;
    font-weight: 500;
    border: 0 !important;
    border-radius: 0.4rem;
    transition: 0.35s all;
}
div.dt-container .dt-paging .dt-paging-button.disabled:hover {
    background: #fff;
    color: #0c1427;
}
div.dt-container .dt-paging .dt-paging-button:hover,
div.dt-container .dt-paging .dt-paging-button.current,
div.dt-container .dt-paging .dt-paging-button.current:hover {
    background: #0c1427;
    color: #fff !important;
}
div.dt-container .dt-paging .dt-paging-button {
    margin: 0.2rem;
}
div.dt-container .dt-paging .dt-paging-button:last-child {
    margin-right: 0;
}
div.dt-container .dt-paging .dt-paging-button:first-child {
    margin-right: 0;
}

div.dt-container .dt-paging .dt-paging-button .fa::after {
    font-family: 'feather' !important;
    font-style: normal;
    position: relative;
    top: 1px;
}
div.dt-container .dt-paging .dt-paging-button.first .fa::after {
    content: '\e933';
}
div.dt-container .dt-paging .dt-paging-button.previous .fa::after {
    content: '\e92f';
}
div.dt-container .dt-paging .dt-paging-button.next .fa::after {
    content: '\e930';
}
div.dt-container .dt-paging .dt-paging-button.last .fa::after {
    content: '\e934';
}

table.dataTable th.dt-type-numeric,
table.dataTable th.dt-type-date,
table.dataTable td.dt-type-numeric,
table.dataTable td.dt-type-date {
    text-align: left !important;
}

table .table-content {
    background-color: #afe5ff;
}
table.dataTable td.dt-type-date {
    text-align: left !important;
}

.shift-contact-profile-box .contact-data-info {
    margin-bottom: 0.3rem;
}
.shift-contact-profile-box h4 {
    font-size: 1.042vw;
    margin-top: 0.4rem;
    margin-bottom: 0.2rem;
}
.shift-contact-profile-box .shift-profile-right-box h4 {
    font-size: 0.833vw;
}
.shift-contact-profile-box .contact-data-info p {
    font-size: 0.729vw !important;
}
.shift-contact-profile-box .contact-data-info .shift-time {
    font-size: 0.625vw !important;
    text-align: right;
}
.shift-contact-profile-box .contact-data-info .badge {
    font-size: 0.677vw;
}
.shift-profile-right-box {
    margin-right: 0.5rem;
}
.nurse-rating-box {
    font-size: 14px;
}
.modal-content.small-model.fixwidth {
    max-width: 383px;
}
.rating-model-btn.btn {
    min-width: 91px;
}
.profile-border {
    border: 1px solid #88c9e3 !important;
}
.nav-item {
    font-size: 18px;
    font-weight: bold;
}
.breadcrumb-item a {
    color: $secondary;
}
.breadcrumb-item.active {
    color: $primary;
}
div.dt-container div.dt-layout-row div.dt-layout-cell.dt-layout-start {
    justify-content: flex-end;
}
div.dt-container div.dt-layout-row div.dt-layout-cell.dt-layout-end {
    justify-content: flex-start;
}
.dt-container .dt-layout-row:first-child:not(.dt-layout-table) .dt-layout-cell {
    width: 100%;
}
.date-input.dropdown-box {
    cursor: pointer;
}

.date-input.dropdown-box input[readonly] {
    cursor: pointer; /* Optional: ensures pointer cursor on readonly input */
}

// shift Quick link styles
.shift-buttons {
    padding: 10px 0;
}

.button-row {
    display: flex;
    flex-wrap: nowrap; /* Ensures all buttons stay on one row */
    overflow-x: auto; /* Allows horizontal scrolling if buttons overflow */
    justify-content: space-between; /* Evenly space buttons */
    gap: 10px; /* Adds consistent gap between buttons */
    padding: 10px 0; /* Add some top and bottom padding */
}

.action-btn {
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    background-color: $white;
    cursor: pointer;
    font-weight: bolder;
    white-space: nowrap; /* Prevent text from wrapping */
    border: none;
    flex: 1 1 auto; /* Buttons grow equally and stay flexible */
    border: 1px solid;
}

/* Background colors for different actions */
.bg-completed {
    color: $btn-green;
}

.btn-canceled {
    color: $btn-red;
}

.bg-scheduled {
    color: $btn-blue;
}

.bg-invited {
    color: $btn-yellow;
}

.bg-actnow {
    color: $btn-saffron;
}
.bg-release {
    color: $btn-purple;
}

.bg-open {
    color: $btn-gray;
}

.text-color {
    background-color: $white;
}

/* Active button styling */
.active-btn,
.action-btn:hover,
.active-btn:hover {
    background-color: $btn-dark-gray; /* Active background color */
    color: $white; /* Active text color */
}

.shift-buttons {
    white-space: nowrap;
    overflow-x: auto;
    gap: 10px;
}

.filter-btn {
    background: $secondary;
    color: $white;
    border: 1px solid transparent;
}

.filter-btn:hover {
    background: $gray-600;
    color: $white;
    border: 1px solid transparent;
}
.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
    align-items: center;
    padding-top: 2px;
    font-family: 'Red Hat Display', sans-serif;
    font-size: 0.9rem;
    color: $gray-600;
    background-color: transparent;
}
.mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input,
.mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
    background: transparent;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: $white;
}

.dropdown-z-index .ng-dropdown-panel .scroll-host {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background-color: #fff;
    border: 1px solid #e9ecef;
    border-top: 0;
    border-radius: 0.25rem;
    z-index: 1;
}

.specialty-lable {
    margin-top: 4px;
}

.nurse-email:hover {
    font-weight: bolder;
}

#tblCustom_info {
    margin-left: 170px !important;
    white-space: nowrap;
}
.dt-length {
    position: absolute !important;
    bottom: 5px !important;
    left: 0 !important;
    justify-content: start !important;
    display: none !important;
}
.dt-length.data {
    display: block !important; /* Or any other style you wish to apply */
    /* Additional styles here */
}
div.dt-container div.dt-layout-row div.dt-layout-cell.dt-layout-end .dt-paging nav {
    display: flex;
    padding-left: 1rem;
}
.filter-create-btn {
    position: absolute;
    z-index: 9;
    right: 50px !important;
    margin-top: 14px;
}

.p-calendar input {
    border: 1px solid $secondary;
    border-radius: 6px;
}

p-calendar .p-inputtext::placeholder {
    font-size: 14px; /* Adjust the size as needed */
    color: $gray-500; /* Specify the desired color for the placeholder text */
}

.shift-history-btn {
    border-radius: 5px;
    padding: 4px;
    font-size: 16px;
    background-color: $white;
    cursor: pointer;
    white-space: nowrap; /* Prevent text from wrapping */
    border: none;
    flex: 1 1 auto; /* Buttons grow equally and stay flexible */
    border: 1px solid;
}

.shift-history-active-btn,
.shift-history-btn:hover,
.shift-history-active-btn:hover {
    background-color: $primary; /* Active background color */
    color: $white; /* Active text color */
}
.searchShort .ng-select-container {
    padding: 0px !important;
}

.custom-popup-new {
    // height: 400px !important;
    width: 380px !important;
    padding: 0 !important;
}
.notification-badge {
    position: absolute;
    top: -6px;
    right: -6px;
    background-color: $primary-btn;
    color: $white;
    border-radius: 50%;
    padding: 4px 4px;
    font-size: 12px;
    line-height: 1;
    display: inline-block;
    min-width: 16px;
    text-align: center;
    font-weight: bolder;
}
/* Hides all labels in Mobiscroll Datepickers */
.mbsc-datepicker .mbsc-label {
    display: none !important; /* Force hiding the label */
}

/* Further adjust to remove any spacing or padding that might be left */
.mbsc-datepicker .mbsc-control {
    margin-top: 0 !important; /* Removes top margin if set */
    padding-top: 0 !important; /* Adjust padding if necessary */
}

.mbsc-datepicker-wrapper {
    position: relative;
}

.datepicker-placeholder {
    position: absolute;
    top: 0;
    left: 12px; /* Adjust based on your design */
    color: grey;
    pointer-events: none; /* Allows clicking through the label */
    font-size: 16px; /* Match your input font size */
    padding: 8px 0; /* Adjust for vertical centering */
}

.mbsc-datepicker-header .mbsc-datepicker-label {
    display: none !important; /* Force the label to not display */
}

/* Adjust the styling of the Mobiscroll input field */
.mbsc-datepicker-input {
    width: 100%; /* Ensure input width matches the container for alignment */
    border: 1px solid #ccc; /* Optional: adds a border to the input */
    padding: 8px 10px; /* Provide ample padding for the text */
}

/* Ensure that the container does not have excess padding or margins that create space */
.mbsc-datepicker {
    padding: 0; /* Remove padding around the datepicker */
    margin: 0; /* Remove margins around the datepicker */
}

/* Optional: If using a custom wrapper, adjust its padding and margins too */
.custom-datepicker-wrapper {
    padding: 0;
    margin: 0; /* Adjust according to your layout needs */
}

.mbsc-datepicker-input {
    width: 100%;
    border: 1px solid #ccc;
    padding: 8px 12px;
}
.mbsc-datepicker-wrapper label {
    display: none !important;
}
.mbsc-datepicker-wrapper .mbsc-ios.mbsc-textfield-wrapper {
    margin-top: 0px !important;
}

.mbsc-ios.mbsc-textfield-box,
.mbsc-ios.mbsc-textfield-outline {
    border-radius: 0.5em !important;
    border: 1px solid $black !important;
    height: 38px !important;
    font-family: 'Red Hat Display', sans-serif;
}
// .datepicker-placeholder {
//     color: #ccc; /* Placeholder text color */
//     position: absolute; /* Position it over the input */
//     top: 0;
//     left: 10px;
//     pointer-events: none; /* Allows clicks to pass through to the input */
//     opacity: 0.75; /* Makes it slightly transparent */
// }

.add-nurse-profile-box .profile-model-img {
    width: 4.01vw;
    min-width: 4.01vw;
}
.add-nurse-profile-box .profile-model-info h4 {
    font-size: 1.05vw;
    margin-bottom: 0.2rem;
}
.add-nurse-profile-box .profile-model-info .label-list .badge {
    font-size: 0.9vw;
}

.nowrap {
    white-space: nowrap;
}
.image-file-name {
    color: #103558;
    cursor: pointer;
    margin-top: 8px;
    font-size: 14px;
    font-weight: bolder;
}
.custom-warning-box {
    background-color: #ffd9cd; /* Light yellow */
    color: $red; /* Dark yellow text */
    border: 1px solid $red; /* Red border */
    padding: 10px;
    border-radius: 5px; /* Rounded corners */
    font-weight: bold;
    margin: 5px 0;
}
// div .ng-dropdown-panel .ng-dropdown-panel-items {
//     max-height: 150px;
// }
.general-data-form {
    position: relative;
    z-index: 2;
}
.general-data-form .ng-dropdown-panel .ng-dropdown-panel-items {
    max-height: 15vh;
}
.dataTable-column-align.ellipsis {
    max-width: 300px !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ng-select.ng-select-opened .ng-select-container {
    border-color: #103558;
    border-radius: 6px !important;
}
.ng-select.ng-select-multiple .ng-select-container {
    overflow: auto;
    align-items: self-start;
}
.ng-select.ng-select-multiple .ng-select-container .ng-arrow-wrapper {
    margin-top: 6px;
}
.ng-select.ng-select-multiple .ng-select-container .ng-clear-wrapper {
    margin-top: 5px;
    margin-left: 7px;
}
.page-footer.card-footer {
    margin: 0;
    display: flex;
    justify-content: end;
    padding: 0.5rem 1rem;
}
/* Multi select css update*/
.ng-select.ng-select-multiple div.ng-select-container .ng-value-container {
    flex-wrap: nowrap;
    overflow: hidden;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: transparent;
    color: #000;
    margin-bottom: 0;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
    display: none;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
    padding: 0;
    font-size: 0.85rem;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label:after {
    content: ',';
}
.ng-select.ng-select-multiple
    .ng-select-container
    .ng-value-container
    .ng-value:nth-last-child(2)
    .ng-value-label:after {
    display: none;
}
.profile-nav-new.profile-model-nav .nav.nav-tabs {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    background: rgba(0, 0, 0, 0.07);
}

.profile-nav-new .nav.nav-tabs .nav-item .nav-link:not(.active) {
    background: transparent;
    border-radius: 0;
}
.profile-nav-new .dt-length {
    left: 10px !important;
}
.profile-nav-new div.dt-container div.dt-layout-row div.dt-layout-cell.dt-layout-end {
    margin-right: 10px;
}
.form-label:empty,
form label:empty {
    display: none;
}
.input-group-text {
    border-radius: 0.35rem;
    border-left: none;
}
.ngb-dp-day.disabled .custom-day {
    color: #bab9b9; /* Light gray text */
    pointer-events: none; /* Prevent interaction */
    cursor: not-allowed;
}

.expand-icon,
.expand-icon:hover {
    background-color: $light-red;
    width: 30px;
    height: 30px;
    font-size: large;
    color: $secondary;
}
.wd-260 {
    width: 260px;
}
.ng-select .ng-select-container .ng-clear-wrapper .ng-clear {
    font-size: 25px;
}