// Bootstrap Alert

@each $state, $value in $theme-colors {
    // Basic alerts
    .alert-#{$state} {
        background-color: rgba($value, 0.1);
        color: darken($value, 5%);
        border-color: rgba($value, 0.2);
        .alert-link {
            color: darken($value, 5%);
        }
        svg {
            width: 19px;
            height: 19px;
            margin-right: 0.5rem;
        }
        i {
            font-size: 19px;
            margin-right: 0.5rem;
        }
    }

    // Fill alerts
    .alert-fill-#{$state} {
        @include alert-variant($value, $value, $white);
        &.alert-fill-light {
            color: $text-muted;
        }
    }
}

// temporary fix
ngb-alert {
    &:not(.show) {
        display: none;
    }
}
