// Dropdowns

.dropdown,
.btn-group {
    .dropdown-toggle {
        &.no-dropdown-toggle-icon {
            &::after {
                display: none;
            }
            &:active {
                border-color: transparent;
            }
        }
        &:after {
            border-top: 0;
            border-right: 0;
            border-left: 0;
            border-bottom: 0;
            font: normal normal normal 24px/1 'feather';
            content: '';
            width: auto;
            height: auto;
            vertical-align: middle;
            line-height: 0.625rem;
            font-size: 0.875rem;
        }
    }
    &.dropup {
        .dropdown-toggle {
            &::after {
                content: '';
            }
        }
    }
    &.dropstart,
    &[placement='start-top'],
    &[placement='start-bottom'] {
        .dropdown-toggle {
            &::after {
                content: '';
            }
            &::before {
                border: 0;
                font: normal normal normal 24px/1 'feather';
                content: '';
                width: auto;
                height: 0.875rem;
                display: inline-block;
                vertical-align: middle;
                line-height: 0.625rem;
                font-size: 0.875rem;
                margin-right: $caret-spacing;
            }
            &:empty::before {
                margin-right: 0;
            }
        }
    }
    &.dropend,
    &[placement='end-top'],
    &[placement='end-bottom'] {
        .dropdown-toggle {
            &::after {
                content: '';
            }
        }
    }
}

.dropdown-menu {
    padding: 0.35rem;
    margin-top: 0;
    box-shadow: $dropdown-box-shadow;
}

.dropdown-item {
    font-size: 0.912rem;
    padding: 0.25rem 0.875rem;
    border-radius: 2px;
    i,
    svg {
        color: $text-muted;
    }
    &:not(&:active, &.active):hover {
        background-color: rgba($primary, 0.1);
        &,
        i,
        svg {
            color: $primary;
        }
    }
    &:active,
    &.active {
        i,
        svg {
            color: $white;
        }
    }
}
