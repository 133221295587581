// Plugin: FullCalendar Angular Component
// github: https://github.com/fullcalendar/fullcalendar-angular

full-calendar.fc {
    .fc-button-primary {
        // @extend .btn;
        // @extend .btn-outline-primary;
        // @extend .btn-sm;
    }
    .fc-button-primary:not(:disabled).fc-button-active,
    .fc-button-primary:not(:disabled):active {
        // @extend .btn-orange;
        background-color: $component-active-bg;
        border-color: $component-active-bg;
    }
    .fc-button .fc-icon {
        font-size: 1.2 em;
    }
    .fc-button-primary:focus,
    .fc-button-primary:not(:disabled).fc-button-active:focus,
    .fc-button-primary:not(:disabled):active:focus {
        box-shadow: none;
    }

    .fc-button-primary:disabled {
        border-color: $primary;
    }

    .fc-daygrid-day-number,
    .fc-col-header-cell-cushion {
        color: rgba($secondary, 0.5);
    }

    .fc-daygrid-event {
        padding: 4px;
        border: 0;
        border-left: 4px solid transparent;
        border-radius: 2px;
    }

    .fc-h-event .fc-event-main,
    .fc-v-event .fc-event-main {
        color: $body-color;
    }

    .fc-h-event,
    .fc-v-event {
        background-color: rgba($info, 0.25);
        border-color: $info;
    }

    .fc-daygrid-day.fc-day-today {
        background-color: rgba($primary, 0.2);
    }

    .fc-list-event:hover td {
        background-color: rgba($primary, 0.2);
    }

    .fc-list-day-text,
    .fc-list-day-side-text {
        color: $body-color;
    }

    .fc-timegrid-event-harness-inset .fc-timegrid-event,
    .fc-timegrid-event.fc-event-mirror,
    .fc-timegrid-more-link {
        box-shadow: none;
    }
}

full-calendar.fc-theme-standard td,
full-calendar.fc-theme-standard th,
full-calendar.fc-theme-standard .fc-scrollgrid {
    border-color: $border-color;
}

.fc.fc-theme-standard .fc-popover {
    background-color: $dropdown-bg;
    border-color: $dropdown-border-color;
    box-shadow: $dropdown-box-shadow;
    .fc-popover-header {
        background-color: $secondary;
        color: $light;
    }
}

.fc.fc-theme-standard .fc-list-day-cushion {
    background-color: $light;
}

.fc.fc-theme-standard .fc-list {
    border-color: $border-color;
}

.fc-event {
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 2px;
    // background: rgba($primary, .2);
    border: 0;
    border-left: 3px solid transparent;
    color: $body-color;
    font-weight: 500;
    font-size: 0.85em;
}

.fc-header-toolbar .fc-toolbar-chunk {
    .fc-prev-button,
    .fc-next-button {
        background: $white;
        color: rgba($secondary, 0.4);
        border-color: rgba($secondary, 0.1);
        transition: 500ms all;
        &:hover {
            color: $white;
            background: $primary;
            border-color: $primary;
        }
    }
    .fc-today-button {
        background: $white;
        color: $secondary;
        border-color: rgba($secondary, 0.1);
        transition: 500ms all;
        &:hover {
            color: $white;
            background: $primary;
            border-color: $primary;
        }
    }
    .fc-button-primary:disabled {
        border: 0;
        background: rgba($secondary, 0.1);
        color: $secondary;
        margin-left: 0px !important;
        &:hover {
            background: rgba($secondary, 0.1);
            color: $secondary;
            margin-left: 0px !important;
        }
    }
}
.fc .fc-toolbar-chunk {
    .fc-toolbar-title {
        font-size: 1rem;
        text-transform: uppercase;
    }
}

.fc-header-toolbar .fc-toolbar-chunk {
    .fc-dayGridMonth-button,
    .fc-timeGridWeek-button,
    .fc-timeGridDay-button {
        background: rgba($primary, 0.2);
        color: $primary;
        border-color: rgba($primary, 0.2);
        transition: 500ms all;
        &:hover {
            color: $white;
            border-color: $primary;
            background: $primary;
        }
    }
}
.fc .fc-daygrid-day-top .fc-daygrid-day-number {
    padding: 0.6rem 0.8rem;
    font-size: 1rem;
    font-weight: 600;
}
full-calendar.fc .fc-daygrid-day.fc-day-today {
    background: rgba($primary, 0.2);
    border-color: rgba($primary, 0.2);
    .fc-daygrid-day-number {
        color: $primary;
    }
}
full-calendar.fc-theme-standard th {
    th {
        padding: 0.4rem;
    }
    .fc-col-header-cell-cushion {
        color: $secondary;
    }
}
@media (max-width: 600px) {
    .fc .fc-toolbar.fc-header-toolbar {
        flex-direction: column;
    }
    .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
        margin: 0.4rem 0;
    }
}
