// Plugin: angular-archwizard
// github: https://github.com/madoar/angular-archwizard

aw-wizard-navigation-bar ul.steps-indicator li a .label {
    color: $text-muted;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li .step-indicator {
    // background-color: $border-color
    border: 8px double $gray-200;
    background-color: $white;
    z-index: 2;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.current .step-indicator {
    border-color: $primary;
    background-color: $white;
}
aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.current a .label {
    color: $primary;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.done .step-indicator {
    // background-color: $success;
    background-color: $white;
    border-color: $success;
}
aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.done a .label {
    color: $success;
}

aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li:not(:last-child):after {
    background-color: $border-color;
}
aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.done:not(:last-child):after {
    background-color: $success;
}

@media only screen and (max-width: 767px) {
    aw-wizard-navigation-bar ul.steps-indicator li a .label {
        font-size: 12px;
    }
}
@media only screen and (max-width: 575px) {
    aw-wizard-navigation-bar.horizontal ul.steps-indicator {
        flex-direction: column;
    }
    aw-wizard-navigation-bar.horizontal.small ul.steps-indicator {
        padding: 0 0 0 20px;
    }
    aw-wizard-navigation-bar.horizontal ul.steps-indicator li {
        text-align: left;
    }
    aw-wizard-navigation-bar.horizontal ul.steps-indicator.steps-4 li {
        width: 100%;
    }
    aw-wizard-navigation-bar.horizontal ul.steps-indicator li a .label {
        padding: 10px;
    }
    aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li .step-indicator {
        top: 8px;
        left: -18px;
    }
    aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li:not(:last-child)::after {
        height: 100%;
        width: 1px;
        top: 16px;
        left: -10px;
    }
}
