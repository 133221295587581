.page-wrapper {
    &.full-page {
        .page-content {
            min-height: 100vh;
            max-width: 100%;
        }
    }
    @media (max-width: 991px) {
        // margin-top: $navbar-height;
    }
    .page-content {
        padding: 10px 30px 30px 30px !important;
        @include make-container();
        @extend .container;
        // min-height: calc(100vh - #{$navbar-height} - 50px);
        min-height: calc(100vh - #{$navbar-height} - 2vw);
        // flex-grow: 1;
        width: calc(100% - 282px);
        margin-left: auto;
        margin-right: 0;
        max-width: 100%;
        // padding-right: 30px;
        // padding-left: 30px;
        // padding-top: 10px;

        .content-nav-wrapper {
            padding: 0;
            position: sticky;
            top: 80px;
            height: calc(100vh - 6rem);
            overflow-y: auto;
            border-left: 1px solid $border-color;
            display: none;
            @media (min-width: 1200px) {
                display: block;
            }
            .content-nav {
                padding: 0px 25px;
                .nav-item {
                    .nav-link {
                        padding: 0;
                        height: 30px;
                        white-space: nowrap;
                        color: $text-muted;
                        font-size: $font-size-base;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
}

.page-wrapper-shift {
    &.full-page {
        .page-content-shift {
            min-height: 100vh;
            max-width: 100%;
        }
    }
    @media (max-width: 991px) {
        margin-top: $navbar-height;
    }
    .page-content-shift {
        // padding: 2rem 0;
        padding: 1rem 2rem !important;
        @include make-container();
        @extend .container;
        min-height: calc(100vh - #{$navbar-height} - 50px);
        flex-grow: 1;
        // max-width: 1700px !important;
        max-width: 100% !important;

        .content-nav-wrapper {
            padding: 0;
            position: sticky;
            top: 80px;
            height: calc(100vh - 6rem);
            overflow-y: auto;
            border-left: 1px solid $border-color;
            display: none;
            @media (min-width: 1200px) {
                display: block;
            }
            .content-nav {
                padding: 0px 25px;
                .nav-item {
                    .nav-link {
                        padding: 0;
                        height: 30px;
                        white-space: nowrap;
                        color: $dark-gray;
                        font-size: $font-size-base;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
}

.page-wrapper-main {
    &.full-page {
        .page-content-main {
            min-height: 100vh;
            max-width: 80%;
        }
    }
    @media (max-width: 991px) {
        margin-top: $navbar-height;
    }
    .page-content-main {
        // padding: 2rem 0;
        padding: 1rem 2rem !important;
        @include make-container();
        @extend .container;
        min-height: calc(100vh - #{$navbar-height} - 50px);
        flex-grow: 1;
        // max-width: 1700px !important;
        max-width: 100% !important;

        .content-nav-wrapper {
            padding: 0;
            position: sticky;
            top: 80px;
            height: calc(100vh - 6rem);
            overflow-y: auto;
            border-left: 1px solid $border-color;
            display: none;
            @media (min-width: 1200px) {
                display: block;
            }
            .content-nav {
                padding: 0px 25px;
                .nav-item {
                    .nav-link {
                        padding: 0;
                        height: 30px;
                        white-space: nowrap;
                        color: $text-muted;
                        font-size: $font-size-base;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1400px) {
    .page-wrapper .page-content {
        width: calc(100% - 230px);
    }
}
